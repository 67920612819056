import { Component, Input, OnInit } from '@angular/core';
import { PsyChart } from '@app/core/models/psy-chart.model';
import { ChartService } from '@app/core/services/chart.service';
import { ReportService } from '@app/core/services/report.service';

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
})
export class SectionComponent implements OnInit {
  @Input() section: any;
  @Input() userName?: string;
  @Input() userInfoId?: number;
  @Input() testGroupId: number;

  chart: PsyChart;
  selector = '';
  chartName: string;

  constructor(private reportService: ReportService, private chartService: ChartService) {}

  ngOnInit() {
    this.formatSentences();
    this.chartName = this.generateRandomChartName();

    if (this.section?.chartId) {
      this.chartService.getById(this.section?.chartId).subscribe((chart) => {
        this.chart = chart;
        this.selector = `chart-${Math.floor(Math.random() * 1000000)}`;
      });
    }
  }

  formatSentences() {
    if (this.section.type === 'OrdinalParagraph') {
      this.section.topLeadSentence.text = this.reportService.removeSelectionBoundary(this.section.topLeadSentence.text);
      this.section.topLeadSentence.text = this.reportService.removeSpecificHtmlChars(this.section.topLeadSentence.text);
      this.section.bottomLeadSentence.text = this.reportService.removeSelectionBoundary(
        this.section.bottomLeadSentence.text,
      );
      this.section.bottomLeadSentence.text = this.reportService.removeSpecificHtmlChars(
        this.section.bottomLeadSentence.text,
      );
    } else if (this.section.type === 'Chart') {
      if (this.section.agilitySentence?.text) {
        this.section.agilitySentence.text = this.reportService.removeSpecificHtmlChars(
          this.section.agilitySentence.text,
        );
      }
    } else {
      this.section.leadSentence.text = this.reportService.removeSelectionBoundary(this.section.leadSentence.text);
      this.section.leadSentence.text = this.reportService.removeSpecificHtmlChars(this.section.leadSentence.text);
    }
    if (typeof this.section.sentences === 'object') {
      Object.keys(this.section.sentences).forEach((key) => {
        this.section.sentences[key].text = this.reportService.removeSelectionBoundary(this.section.sentences[key].text);
        this.section.sentences[key].text = this.reportService.removeSpecificHtmlChars(this.section.sentences[key].text);
      });
    }
  }

  generateRandomChartName(): string {
    const randomNumber = Math.floor(Math.random() * 1000001);
    return randomNumber.toString();
  }
}
