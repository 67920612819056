/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { ChartDescription } from '../models/ChartDescription';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class CircularPlotController {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param userId
     * @param testGroupId
     * @param chartId
     * @returns string
     * @throws ApiError
     */
    public circularPlotControllerCircularChart(
        userId: number,
        testGroupId: number,
        chartId: number,
    ): Observable<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v3/user/{userId}/testGroup/{testGroupId}/chart/{chartId}/circular-chart.svg',
            path: {
                'userId': userId,
                'testGroupId': testGroupId,
                'chartId': chartId,
            },
        });
    }

    /**
     * @param userId
     * @param testGroupId
     * @param chartId
     * @returns string
     * @throws ApiError
     */
    public circularPlotControllerCircularChartPng(
        userId: number,
        testGroupId: number,
        chartId: number,
    ): Observable<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v3/user/{userId}/testGroup/{testGroupId}/chart/{chartId}/circular-chart.png',
            path: {
                'userId': userId,
                'testGroupId': testGroupId,
                'chartId': chartId,
            },
        });
    }

    /**
     * @param userInfoId
     * @param testGroupId
     * @param chartId
     * @returns ChartDescription
     * @throws ApiError
     */
    public circularPlotControllerCircularChartDescription(
        userInfoId: number,
        testGroupId: number,
        chartId: number,
    ): Observable<ChartDescription> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v3/userInfoId/{userInfoId}/testGroup/{testGroupId}/chart/{chartId}/description',
            path: {
                'userInfoId': userInfoId,
                'testGroupId': testGroupId,
                'chartId': chartId,
            },
        });
    }

}
