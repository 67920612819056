import { Component, Input } from '@angular/core';
import { Chapter } from '@app/core/models/chapter.model';

@Component({
  selector: 'app-chapter',
  templateUrl: './chapter.component.html',
  styleUrls: ['./chapter.component.scss'],
})
export class ChapterComponent {
  @Input() chapter: Chapter;
  @Input() userName?: string;
  @Input() userInfoId?: number;
  @Input() testGroupId?: number;

  constructor() {}
}
