/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { Petal } from '../models/Petal';
import type { Pistil } from '../models/Pistil';
import type { UpdateChartRangesInput } from '../models/UpdateChartRangesInput';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class ChartRangeController {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param requestBody
     * @returns boolean
     * @throws ApiError
     */
    public chartRangeControllerUpdateChartPistilRanges(
        requestBody: UpdateChartRangesInput,
    ): Observable<boolean> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v3/chart/pistil-ranges',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param chartId
     * @returns Pistil
     * @throws ApiError
     */
    public chartRangeControllerGetChartPistilRanges(
        chartId: number,
    ): Observable<Array<Pistil>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v3/chart/{chartId}/pistil-ranges',
            path: {
                'chartId': chartId,
            },
        });
    }

    /**
     * @param requestBody
     * @returns boolean
     * @throws ApiError
     */
    public chartRangeControllerUpdateChartPetalRanges(
        requestBody: UpdateChartRangesInput,
    ): Observable<boolean> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v3/chart/petal-ranges',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param chartId
     * @returns Petal
     * @throws ApiError
     */
    public chartRangeControllerGetChartPetalRanges(
        chartId: number,
    ): Observable<Array<Petal>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v3/chart/{chartId}/petal-ranges',
            path: {
                'chartId': chartId,
            },
        });
    }

}
