<h1>{{ scoreCardsSection.heading }}</h1>
<div *ngIf="chart?.id">
  <span
    >Chart: <b>{{ chart.name }}</b></span
  >
  <div class="chart-preview" [ngSwitch]="chart.base">
    <app-hexagon class="chart" *ngSwitchCase="'HEXAGON'" [chartId]="chart.id" [testGroupId]="1"> </app-hexagon>

    <app-circular-plot class="chart" *ngSwitchCase="'FOUR_PLUS_ONE_CARTESIAN'" [chartId]="chart.id" [testGroupId]="1">
    </app-circular-plot>

    <app-square-plot class="chart" *ngSwitchCase="'CIRCLE_IN_CARTESIAN'" [chartId]="chart.id" [testGroupId]="1">
    </app-square-plot>

    <app-blossom-graph-preview class="chart" *ngSwitchCase="'BLOSSOM_GRAPH'" [chartId]="chart.id" [testGroupId]="1">
    </app-blossom-graph-preview>
  </div>
</div>

<table *ngIf="scoreCardsSection">
  <colgroup>
    <col style="width: 10rem" />
    <col class="range" />
    <col class="range" />
    <col class="range" />
    <col class="range" />
    <col class="range" />
  </colgroup>
  <thead>
    <tr>
      <th></th>
      <th colspan="5">Preferred Ranges</th>
    </tr>
    <tr>
      <th>Subscale</th>
      <th>Out of Range: Low</th>
      <th>Cautionary: Low</th>
      <th>Optimal</th>
      <th>Out of Range: High</th>
      <th>Cautionary: High</th>
    </tr>
    <tr>
      <th></th>
      <th>From - To</th>
      <th>From - To</th>
      <th>From - To</th>
      <th>From - To</th>
      <th>From - To</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let scoreCard of scoreCardsSection.scoreCards">
      <td>
        {{ scoreCard.subscale?.title }} <br />
        {{ scoreCard.superscale?.title }}
      </td>
      <td class="range" (click)="openRangeTextPreview(rangeTextPreviewDialog, scoreCard.ranges.outOfRangeLow.text)">
        {{ scoreCard.ranges.outOfRangeLow.from }} - {{ scoreCard.ranges.outOfRangeLow.to }}
        <p [innerHtml]="scoreCard.ranges.outOfRangeLow.text"></p>
      </td>
      <td class="range" (click)="openRangeTextPreview(rangeTextPreviewDialog, scoreCard.ranges.cautionaryLow.text)">
        {{ scoreCard.ranges.cautionaryLow.from }} - {{ scoreCard.ranges.cautionaryLow.to }}
        <p [innerHtml]="scoreCard.ranges.cautionaryLow.text"></p>
      </td>
      <td class="range" (click)="openRangeTextPreview(rangeTextPreviewDialog, scoreCard.ranges.optimal.text)">
        {{ scoreCard.ranges.optimal.from }} - {{ scoreCard.ranges.optimal.to }}
        <p [innerHtml]="scoreCard.ranges.optimal.text"></p>
      </td>
      <td class="range" (click)="openRangeTextPreview(rangeTextPreviewDialog, scoreCard.ranges.outOfRangeHigh.text)">
        {{ scoreCard.ranges.outOfRangeHigh.from }} - {{ scoreCard.ranges.outOfRangeHigh.to }}
        <p [innerHtml]="scoreCard.ranges.outOfRangeHigh.text"></p>
      </td>
      <td class="range" (click)="openRangeTextPreview(rangeTextPreviewDialog, scoreCard.ranges.cautionaryHigh.text)">
        {{ scoreCard.ranges.cautionaryHigh.from }} - {{ scoreCard.ranges.cautionaryHigh.to }}
        <p [innerHtml]="scoreCard.ranges.cautionaryHigh.text"></p>
      </td>
    </tr>
  </tbody>
</table>

<ng-template #rangeTextPreviewDialog>
  <h1 mat-dialog-title>See range text</h1>
  <p mat-dialog-content [innerHtml]="currentRangeText"></p>
  <div align="end" mat-dialog-actions>
    <button mat-flat-button mat-dialog-close>
      <mat-icon>block</mat-icon>
      Cancel
    </button>
  </div>
</ng-template>
