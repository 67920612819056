<h1>{{ combinationParagraph.heading }}</h1>

<div *ngIf="chart?.id">
  <span
    >Chart: <b>{{ chart.name }}</b></span
  >
  <div class="chart-preview" [ngSwitch]="chart.base">
    <app-hexagon class="chart" *ngSwitchCase="'HEXAGON'" [chartId]="chart.id" [testGroupId]="1"> </app-hexagon>

    <app-circular-plot class="chart" *ngSwitchCase="'FOUR_PLUS_ONE_CARTESIAN'" [chartId]="chart.id" [testGroupId]="1">
    </app-circular-plot>

    <app-square-plot class="chart" *ngSwitchCase="'CIRCLE_IN_CARTESIAN'" [chartId]="chart.id" [testGroupId]="1">
    </app-square-plot>

    <app-blossom-graph-preview class="chart" *ngSwitchCase="'BLOSSOM_GRAPH'" [chartId]="chart.id" [testGroupId]="1">
    </app-blossom-graph-preview>
  </div>
</div>

<p [innerHtml]="leadSentence"></p>

<div class="subscales">
  <table *ngIf="combinationParagraph.useSubscale">
    <colgroup>
      <col />
      <col class="num-col" />
    </colgroup>
    <thead>
      <tr>
        <th>Subscales Left</th>
        <th>Weight</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let subscale of combinationParagraph.subscales.left">
        <td>{{ subscale.title }}</td>
        <td>{{ subscale.weight }}</td>
      </tr>
    </tbody>
  </table>

  <table *ngIf="combinationParagraph.useSubscale">
    <colgroup>
      <col />
      <col class="num-col" />
    </colgroup>
    <thead>
      <tr>
        <th>Subscales Right</th>
        <th>Weight</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let subscale of combinationParagraph.subscales.right">
        <td>{{ subscale.title }}</td>
        <td>{{ subscale.weight }}</td>
      </tr>
    </tbody>
  </table>

  <table *ngIf="!combinationParagraph.useSubscale">
    <thead>
      <tr>
        <th>Superscales Left</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let superscale of combinationParagraph.superscales.left">
        <td>{{ superscale.title }}</td>
      </tr>
    </tbody>
  </table>

  <table *ngIf="!combinationParagraph.useSubscale">
    <thead>
      <tr>
        <th>Superscales Right</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let superscale of combinationParagraph.superscales.right">
        <td>{{ superscale.title }}</td>
      </tr>
    </tbody>
  </table>
</div>

<div class="double-ranges">
  <table>
    <colgroup>
      <col class="num-col" />
      <col class="num-col" />
      <col class="num-col" />
      <col class="num-col" />
      <col />
    </colgroup>
    <thead>
      <tr>
        <th colspan="2">{{ combinationParagraph.useSubscale ? 'Subscale' : 'Superscale' }} Left</th>
        <th colspan="2">{{ combinationParagraph.useSubscale ? 'Subscale' : 'Superscale' }} Right</th>
      </tr>
      <tr>
        <th>From</th>
        <th>To</th>
        <th>From</th>
        <th>To</th>
        <th>Text</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let doubleRange of combinationParagraph.doubleRanges">
        <td>{{ doubleRange.from1 }}</td>
        <td>{{ doubleRange.to1 }}</td>
        <td>{{ doubleRange.from2 }}</td>
        <td>{{ doubleRange.to2 }}</td>
        <td class="tests">
          <div [innerHtml]="doubleRange.text.he"></div>
          <div [innerHtml]="doubleRange.text.she"></div>
          <div [innerHtml]="doubleRange.text.they"></div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
