/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { Chart } from '../models/Chart';
import type { UpdateBlossomChartInput } from '../models/UpdateBlossomChartInput';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class ChartController {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param chartId
     * @returns Chart
     * @throws ApiError
     */
    public chartControllerGetBlossomChart(
        chartId: number,
    ): Observable<Chart> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v3/chart/{chartId}/blossom-chart',
            path: {
                'chartId': chartId,
            },
        });
    }

    /**
     * @param chartId
     * @param requestBody
     * @returns Chart
     * @throws ApiError
     */
    public chartControllerUpdateBlossomChart(
        chartId: number,
        requestBody: UpdateBlossomChartInput,
    ): Observable<Chart> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v3/chart/{chartId}/blossom-chart',
            path: {
                'chartId': chartId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
