import { AfterViewInit, Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import * as d3 from 'd3';
import { forkJoin } from 'rxjs';

// services
import { ChartService } from '@app/core/services/chart.service';
import { UserService } from '@app/core/services/user.service';

// helpers
import { ChartHelper } from '@app/core/helpers/chart-helper';
import { environment } from 'src/environments/environment';
import { AreaIndexService } from '@app/core/services/area-index.service';
import { UtilsService } from '@app/core/services/utils.service';
import { TestGroupChartComparison } from '@app/core/models/test-group-chart-comparison.model';
import { ReportService } from '@app/core/services/report.service';

@Component({
  selector: 'app-circular-plot',
  templateUrl: './circular-plot.component.html',
  styleUrls: ['./circular-plot.component.scss'],
})
export class CircularPlotComponent extends ChartHelper implements OnChanges, AfterViewInit {
  @Input() chartId: number;
  @Input() userId?: number;
  @Input() testGroupId: number;
  @Input() companyId?: number;
  @Input() chartSelectedUsers: any[];
  @Input() visible?: boolean = true;
  @Input() chartName?: string = '1';
  @ViewChild('circleRef') circleRef: ElementRef;
  userColor = '#F1592A'; // color for user
  mainColor = '#425465'; // color for main wave
  rectColor = 'white'; // color for graph division lines
  groupColor = 'white'; // color for group dots
  groupStroke = '#425465'; // stroke for group dots
  groupTextColor = '#4AC29A'; // color for user name or id text
  contourColor = 'white'; // color for contour
  textColor = '#66809a'; // color for graph text and Highlight ideal area of quadrant (topright)
  centerColor = 'white';

  margin = { top: 50, right: 50, bottom: 50, left: 50 };
  width: number;
  height: number;
  svg: any;
  viewBox: any;

  allUsers = [];
  selectedUsers = [];
  chart: any;
  description: string;
  gender: string;

  angleChoice = [315, 45, 0, 225, 135];
  dirs = ['TOP_LEFT', 'TOP_RIGHT', 'CENTER', 'BOTTOM_LEFT', 'BOTTOM_RIGHT'];

  xAxis: any;
  yAxis: any;
  pathNumber: any;
  constructor(
    private chartService: ChartService,
    private userService: UserService,
    private areaIndexService: AreaIndexService,
    private utilService: UtilsService,
    private reportService: ReportService,
  ) {
    super();
    this.width = 500 - this.margin.left - this.margin.right;
    this.height = 500 - this.margin.top - this.margin.bottom;
    this.viewBox = '0 0 ' + (window.innerWidth < 700 ? 550 : 500) + ' ' + 500;
  }

  ngAfterViewInit() {
    if (this.userId) {
      if (!this.companyId) {
        forkJoin([
          this.chartService.getResultsByTestGroup(this.chartId, this.testGroupId),
          this.chartService.getChartWithResultsByUser(this.chartId, this.userId),
        ]).subscribe((results) => {
          this.reportService
            .getAllTestGroupChartComparisonByTestGroupIdAndChartId(this.testGroupId, this.chartId)
            .subscribe((testGroupChartComparisons: TestGroupChartComparison[]) => {
              Object.keys(results[0]).forEach((user) => {
                if (
                  !testGroupChartComparisons.find(
                    (testGroupChartComparison: TestGroupChartComparison) =>
                      testGroupChartComparison.userInfoId === parseInt(user),
                  )
                ) {
                  delete results[0][user];
                }
              });
              this.allUsers = results[0];
              this.chart = results[1];
              if (this.chart.subscales?.length === 0) {
                this.chart.superscales?.map((ss) => (ss.subscaleTitle = ss.title));
                this.chart.subscales = this.chart.superscales;
              }
              this.assignSelectedUsers();
              this.buildSvg();
              this.buildAxes();
              this.buildChart();
            });
        });
      } else {
        forkJoin([
          this.chartService.getResultsByCompany(this.chartId, this.companyId),
          this.chartService.getChartWithResultsByUser(this.chartId, this.userId),
        ]).subscribe((results) => {
          this.allUsers = results[0];
          this.chart = results[1];

          this.assignSelectedUsers();
          this.buildSvg();
          this.buildAxes();
          this.buildChart();
        });
      }
    } else {
      forkJoin([
        this.chartService.getResultsByTestGroup(this.chartId, this.testGroupId),
        this.chartService.getById(this.chartId),
      ]).subscribe((results) => {
        this.allUsers = results[0];
        this.chart = results[1];
        this.assignSelectedUsers();
        this.buildSvg();
        this.buildAxes();
        this.buildChart();

        this.chartService.setChartUsersListener(this.transformUsersData(this.allUsers));
      });
    }
  }

  ngOnChanges() {
    if (this.svg) {
      this.assignSelectedUsers();
      this.buildAxes();
      this.buildChart();
    }
  }

  private async getPathNumberByCoordinates(x, y) {
    d3.xml(`${environment.assetsUrl}/images/four_in_one_cartesian.svg`).then(async (response: any) => {
      document.querySelector('#tempBindCirclePlot').appendChild(response.getElementById('circular_plot_svg'));

      let svg: any = document.getElementById('circular_plot_svg');
      let paths: any = svg.getElementsByTagName('path');
      let point: any = svg.createSVGPoint();
      point.x = x;
      point.y = y;

      for (let path of paths) {
        if (path.isPointInFill(point)) {
          this.pathNumber = path.getAttribute('number');
          break;
        }
      }
      document.querySelector('#tempBindCirclePlot').innerHTML = '';
      let areaPathNumber = await this.areaIndexService.getAreaIndex(this.chart, this.pathNumber);
      if (areaPathNumber) {
        this.chart.area = areaPathNumber;
        this.gender = this.userService.getPronoun(this.chart.user);
        this.description = this.userService.getPronounDescription(this.chart, this.gender);
      } else {
        this.description = null;
      }
    });
  }

  private buildAxes() {
    // set up the x scale
    const xScale = d3.scaleLinear().domain([-100, 100]).range([0, this.width]); // actual length of axis

    // set up the y scale
    const yScale = d3.scaleLinear().domain([-100, 100]).range([this.height, 0]); // actual length of axis

    const rScale = d3.scaleLinear().domain([0, 100]).range([0, this.height]);

    // define the x axis
    this.xAxis = d3.axisBottom(xScale).tickSizeInner(0).tickSizeOuter(0);
    // location of lables (default is bottom)

    // define the y axis
    this.yAxis = d3.axisLeft(yScale).tickSizeInner(0).tickSizeOuter(0);

    const datameas = [
      [0, 0, 50],
      [0, 0, 15],
      [0, 0, 25],
    ];

    const color = d3.scaleOrdinal().range([this.mainColor, this.mainColor, this.centerColor]);

    // Clean up previous line path
    if (this.svg && !this.svg.selectAll('path').empty()) {
      this.svg.selectAll('path').remove();
    }

    this.svg
      .selectAll('g')
      .data(datameas)
      .enter()
      .append('circle')
      .attr('cx', (d) => xScale(d[0]))
      .attr('cy', (d) => yScale(d[1]))
      .attr('r', (d) => rScale(d[2]))
      .style('fill', (d, i) => color(i))
      // .style("stroke", "white")
      // .style("stroke-width", 3)
      .attr('transform', 'translate(200 200) rotate(120)');

    // call the xAxis function to generate the x axis
    this.svg
      .append('g')
      .attr('transform', 'translate(0, ' + this.height / 2 + ')')
      .call(this.xAxis)
      .call((g) => {
        g.select('.domain').attr('stroke', this.rectColor).attr('stroke-width', '2px');
      })
      .selectAll('text')
      .attr('visibility', 'hidden');

    // call the yAxis function to generate the x axis
    this.svg
      .append('g')
      .attr('transform', 'translate(' + this.width / 2 + ', 0)')
      .call(this.yAxis)
      .call((g) => {
        g.select('.domain').attr('stroke', this.rectColor).attr('stroke-width', '2px');
      })
      .selectAll('text')
      .attr('visibility', 'hidden');
  }

  private buildChart() {
    // set up the x scale
    const xScale = d3.scaleLinear().domain([-100, 100]).range([0, this.width]); // actual length of axis

    // set up the y scale
    const yScale = d3.scaleLinear().domain([-100, 100]).range([this.height, 0]); // actual length of axis

    const dataALL = this.selectedUsers;

    const densityData = d3
      .contourDensity()
      .x((d) => xScale(d['x'])) // x and y = column name in .csv input data
      .y((d) => yScale(d['y']))
      .size([this.width, this.height])
      .bandwidth(40)(
      // smaller = more precision in lines = more lines
      dataALL,
    );

    // Add the contour: several "path"
    this.svg
      .selectAll('path')
      .data(densityData)
      .enter()
      .append('path')
      .attr('d', d3.geoPath())
      .attr('fill', 'none')
      .attr('stroke', this.contourColor)
      .attr('stroke-width', 0.3)
      .attr('stroke-linejoin', 'round');

    const div = d3.select('body').append('div').attr('class', 'tooltip-chart').style('opacity', 0);

    // Clean up previous line path
    if (this.svg && !this.svg.selectAll('.user-circle').empty()) {
      this.svg.selectAll('.user-circle').remove();
    }

    this.svg
      .selectAll('dot')
      .data(dataALL)
      .enter()
      .append('circle')
      .attr('class', 'user-circle')
      .attr('cx', (d) => xScale(0))
      .attr('cy', (d) => yScale(0))
      .attr('r', 3)
      .style('fill', this.groupColor)
      .style('stroke', this.groupStroke)
      .style('stroke-width', 0.5)
      .on('mouseover', (event, d) => {
        d3.select(event.currentTarget).transition().duration(100).attr('r', 5);

        d3.select('body')
          .append('div')
          .html(d.userName)
          .attr('class', 'tooltip')
          .style('left', event.pageX + 15 + 'px')
          .style('top', event.pageY - 15 + 'px');
      })
      .on('mouseout', (event, d) => {
        d3.select(event.currentTarget).transition().duration(50).attr('opacity', 1).attr('r', 3);
        d3.selectAll('.tooltip').remove();
      });

    let a = 0;
    let b = 0;

    if (this.chart?.subscales.length) {
      const angle = this.getAngle(
        this.chart.subscales[0].direction,
        this.chart.subscales[1].direction,
        this.chart.subscales[2].direction,
        this.chart.subscales[0].percentile,
      );

      const fin1 = angle.percentile * Math.sin(this.deg2rad(angle.angle));
      a = fin1;
      const fin = angle.percentile * Math.cos(this.deg2rad(angle.angle));
      b = fin;
    }

    if (this.userId) {
      const userData = [
        {
          x: a,
          y: b,
          name: this.chart.user.userName,
        },
      ];
      this.svg
        .data(userData)
        .append('circle')
        .attr('cx', (d) => xScale(0))
        .attr('cy', (d) => yScale(0))
        .attr('r', 7)
        .style('fill', this.userColor)
        .on('mouseover', (event, d) => {
          d3.select(event.currentTarget).transition().duration(100).attr('r', 10);

          d3.select('body')
            .append('div')
            .html(d.name)
            .attr('class', 'tooltip')
            .style('left', event.pageX + 15 + 'px')
            .style('top', event.pageY - 15 + 'px');
        })
        .on('mouseout', (event, d) => {
          d3.select(event.currentTarget).transition().duration(50).attr('opacity', 1).attr('r', 7);
          d3.selectAll('.tooltip').remove();
        });
    }

    this.svg
      .selectAll('circle')
      .transition()
      .delay((d, i) => i * 3)
      .duration(2000)
      .attr('cx', (d) => xScale(d.x))
      .attr('cy', (d) => yScale(d.y));

    this.svg
      .selectAll('dot')
      .transition()
      .delay((d, i) => i * 3)
      .duration(2000)
      .attr('cx', (d) => xScale(d.x))
      .attr('cy', (d) => yScale(d.y));

    this.addCopyright(this.svg, this.width / 2, this.height + this.margin.bottom - 3);

    this.getPathNumberByCoordinates(xScale(a), yScale(b));

    this.chart?.subscales.forEach((susbcale) => {
      switch (susbcale.direction) {
        case 'CENTER':
          this.svg
            .append('text')
            .attr('x', this.width / 2 - this.utilService.getWidth(susbcale.subscaleTitle) / 2)
            .attr('y', this.height / 2)
            .style('font-size', 14)
            .text(susbcale.subscaleTitle)
            .style('font-family', 'Arial')
            .style('fill', this.textColor);
          break;
        case 'TOP_LEFT':
          this.svg
            .append('text')
            .attr('x', -this.utilService.getWidth(susbcale.subscaleTitle) / 2)
            .attr('y', 60)
            .text(susbcale.subscaleTitle)
            .style('font-size', 14)
            .style('font-family', 'Arial')
            .style('fill', this.textColor)
            .attr('transform', 'rotate(-45)');
          break;
        case 'TOP_RIGHT':
          this.svg
            .append('text')
            .attr('x', 285 - this.utilService.getWidth(susbcale.subscaleTitle) / 2)
            .attr('y', -220)
            .attr('transform', 'rotate(45)')
            .style('font-size', 14)
            .text(susbcale.subscaleTitle)
            .style('font-family', 'Arial')
            .style('fill', this.textColor);
          break;
        case 'BOTTOM_LEFT':
          this.svg
            .append('text')
            .attr('x', 225 + this.utilService.getWidth(susbcale.subscaleTitle) / 2)
            .attr('y', 230)
            .attr('transform', 'rotate(45)')
            .text(susbcale.subscaleTitle)
            .style('font-size', 14)
            .style('font-family', 'Arial')
            .style('fill', this.textColor);
          break;
        case 'BOTTOM_RIGHT':
          this.svg
            .append('text')
            .attr('x', -10 - this.utilService.getWidth(susbcale.subscaleTitle) / 2)
            .attr('y', 510)
            .attr('transform', 'rotate(-45)')
            .style('font-size', 14)
            .text(susbcale.subscaleTitle)
            .style('font-family', 'Arial')
            .style('fill', this.textColor);
          break;
        default:
          break;
      }
    });
  }

  formulaDot(user, axis) {
    const max = user[0].percentile ? user[0].percentile : 0;
    const nextmax = user[1] ? user[1].percentile : 0;
    if (user[0] && user[1] && user[2]) {
      const angle = this.getAngle(user[0].direction, user[1].direction, user[2].direction, user[0].percentile);
      if (axis === 'y') {
        return angle.percentile * Math.cos(this.deg2rad(angle.angle));
      } else {
        return angle.percentile * Math.sin(this.deg2rad(angle.angle));
      }
    }
  }

  getAngle(firstDirection, secondDirection, thirdDirection, firstPercentile) {
    let angle = 0;
    switch (firstDirection) {
      case 'TOP_LEFT': {
        angle = 315;
        if (secondDirection === 'BOTTOM_LEFT') {
          angle -= 30;
        } else if (secondDirection === 'TOP_RIGHT') {
          angle += 30;
        } else if (secondDirection == 'CENTER') {
          if (firstPercentile >= 50) firstPercentile = 60;
          else firstPercentile -= 40;
        }

        if (thirdDirection === 'BOTTOM_LEFT') {
          angle -= 30;
        } else if (thirdDirection === 'TOP_RIGHT') {
          angle += 30;
        } else if (thirdDirection == 'CENTER') {
          if (firstPercentile >= 50) firstPercentile = 60;
          else firstPercentile -= 40;
        }

        break;
      }

      case 'TOP_RIGHT': {
        angle = 45;
        if (secondDirection === 'TOP_LEFT') {
          angle -= 30;
        } else if (secondDirection === 'BOTTOM_RIGHT') {
          angle += 30;
        } else if (secondDirection == 'CENTER') {
          if (firstPercentile >= 50) firstPercentile = 60;
          else firstPercentile -= 40;
        }

        if (thirdDirection === 'TOP_LEFT') {
          angle -= 30;
        } else if (thirdDirection === 'BOTTOM_RIGHT') {
          angle += 30;
        } else if (thirdDirection == 'CENTER') {
          if (firstPercentile >= 50) firstPercentile = 60;
          else firstPercentile -= 40;
        }
        break;
      }

      case 'CENTER': {
        firstPercentile = 0;
        angle = 0;
        if (secondDirection === 'TOP_LEFT') {
          angle = 315;
          firstPercentile = 40;
          if (thirdDirection === 'TOP_RIGHT') {
            angle += 30;
          } else if (thirdDirection === 'BOTTOM_LEFT') {
            angle -= 30;
          }
        }

        if (secondDirection === 'TOP_RIGHT') {
          firstPercentile = 40;
          angle = 45;
          if (thirdDirection === 'TOP_LEFT') {
            angle -= 30;
          } else if (thirdDirection === 'BOTTOM_RIGHT') {
            angle += 30;
          }
        }

        if (secondDirection === 'BOTTOM_LEFT') {
          angle = 225;
          firstPercentile = 40;
          if (thirdDirection === 'TOP_LEFT') {
            angle += 30;
          } else if (thirdDirection === 'BOTTOM_RIGHT') {
            angle -= 30;
          }
        }

        if (secondDirection === 'BOTTOM_RIGHT') {
          angle = 135;
          firstPercentile = 40;
          if (thirdDirection === 'TOP_RIGHT') {
            angle -= 30;
          } else if (thirdDirection === 'BOTTOM_LEFT') {
            angle += 30;
          }
        }
        break;
      }

      case 'BOTTOM_LEFT': {
        angle = 225;
        if (secondDirection === 'BOTTOM_RIGHT') {
          angle -= 30;
        } else if (secondDirection === 'TOP_LEFT') {
          angle += 30;
        } else if (secondDirection == 'CENTER') {
          if (firstPercentile >= 50) firstPercentile = 60;
          else firstPercentile -= 40;
        }

        if (thirdDirection === 'BOTTOM_RIGHT') {
          angle -= 30;
        } else if (thirdDirection === 'TOP_LEFT') {
          angle += 30;
        } else if (thirdDirection == 'CENTER') {
          if (firstPercentile >= 50) firstPercentile = 60;
          else firstPercentile -= 40;
        }
        break;
      }

      case 'BOTTOM_RIGHT': {
        angle = 135;
        if (secondDirection === 'TOP_RIGHT') {
          angle -= 30;
        } else if (secondDirection === 'BOTTOM_LEFT') {
          angle += 30;
        } else if (secondDirection == 'CENTER') {
          if (firstPercentile >= 50) firstPercentile = 60;
          else firstPercentile -= 40;
        }

        if (thirdDirection === 'TOP_RIGHT') {
          angle -= 30;
        } else if (thirdDirection === 'BOTTOM_LEFT') {
          angle += 30;
        } else if (thirdDirection == 'CENTER') {
          if (firstPercentile >= 50) firstPercentile = 60;
          else firstPercentile -= 40;
        }
        break;
      }
    }
    return { angle: angle, percentile: firstPercentile };
  }

  deg2rad(degrees) {
    // convert degrees to radians
    return (Math.PI * degrees) / 180;
  }

  private buildSvg() {
    this.svg = d3
      .select('figure#FOUR_PLUS_ONE_CARTESIAN' + this.chartId + this.chartName)
      .append('svg')
      .attr('viewBox', this.viewBox)
      .append('g')
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');
  }

  assignSelectedUsers() {
    this.chartSelectedUsers
      ? (this.selectedUsers = this.chartSelectedUsers)
      : (this.selectedUsers = this.transformUsersData(this.allUsers));
  }

  transformUsersData(users: any[]) {
    let usersData = [];
    for (const userId in users) {
      if (users[userId]) {
        const user = users[userId];
        usersData.push({
          x: this.formulaDot(user, 'x'),
          y: this.formulaDot(user, 'y'),
          userName: user[0].userName,
          userInfoId: user[0].userInfoId,
        });
      }
    }

    for (const userData of usersData) {
      const sameCoordinates = usersData.filter((userData_) => userData_.x === userData.x && userData_.y === userData.y);
      const angle = 360 / sameCoordinates.length;

      for (let i = 1; i < sameCoordinates.length; i++) {
        userData.y += (i <= 7 ? 6 : 10) * Math.sin(angle * i);
        userData.x += (i <= 7 ? 6 : 10) * Math.cos(angle * i);
      }
    }

    return usersData;
  }
}
