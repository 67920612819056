import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, AbstractControl, Validators, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

// constants
import { EVENT_SUCCESS } from '@app/core/constants';
import { PsyChart } from '@app/core/models/psy-chart.model';
import { ChartRangeController } from 'src/generated/v3';
import { SnackBarService } from '@app/core/services/snack-bar.service';

@Component({
  selector: 'app-blossom-chart-petal-ranges',
  templateUrl: './blossom-chart-petal-ranges.component.html',
  styleUrls: ['./blossom-chart-petal-ranges.component.scss'],
})
export class BlossomChartPetalRangesComponent implements OnInit {
  @Input() chart: PsyChart;

  petalForm: FormGroup;
  textForRangeTitle: string;
  addTextDialogRef: MatDialogRef<any>;
  addTextEditorControl = new FormControl('', [Validators.required]);

  constructor(
    private chartRangeController: ChartRangeController,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private snackBar: SnackBarService,
  ) {
    this.petalForm = this.fb.group({
      petals: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    this.getFormData(this.chart.id);
  }

  getFormData(chartId: number) {
    this.chartRangeController.chartRangeControllerGetChartPetalRanges(chartId).subscribe((petals) => {
      this.loadFormData(petals);
    });
  }

  private loadFormData(petals: any[]): void {
    petals.map((petal) => {
      this.petalsFormArray.push(this.createPetalGroup(petal));
    });
  }

  get petalsFormArray() {
    return this.petalForm.get('petals') as FormArray;
  }

  resetPetalsFormArray() {
    (<FormArray>this.petalForm.get('petals')).clear();
  }

  createPetalGroup(petal: any): FormGroup {
    return this.fb.group({
      id: [petal.id],
      label: [petal.label],
      ranges: this.fb.array(
        Array.from({ length: 5 }).map((_, index) => {
          const range = petal.ranges.find((r) => r.order === index);
          return this.fb.group({
            order: [index],
            min: [range ? range.min : 0],
            max: [range ? range.max : 0],
            description: [range ? range.description : ''],
          });
        }),
      ),
    });
  }

  openAddTextDialog(ref: TemplateRef<any>, textControl: AbstractControl, title: string) {
    this.addTextEditorControl.setValue(textControl.value);
    this.textForRangeTitle = title;
    this.addTextDialogRef = this.dialog.open(ref);
    this.addTextDialogRef.afterClosed().subscribe((result) => {
      if (result?.event !== EVENT_SUCCESS) {
        return;
      }
      textControl.setValue(this.addTextEditorControl.value);
      this.addTextEditorControl.setValue('');
    });
  }

  removeTextDialog(textControl: AbstractControl, title: string) {
    this.addTextEditorControl.setValue('');
    this.textForRangeTitle = title;

    textControl.setValue(this.addTextEditorControl.value);
  }

  closeAddTextDialog() {
    this.addTextDialogRef.close();
  }

  saveAddTextDialog() {
    this.addTextDialogRef.close({ event: EVENT_SUCCESS });
  }

  onSave() {
    const ranges = this.clearEmptyRanges();
    this.chartRangeController
      .chartRangeControllerUpdateChartPetalRanges({
        chartId: this.chart.id,
        ranges,
      })
      .subscribe((res) => {
        this.resetPetalsFormArray();
        this.getFormData(this.chart.id);
        this.snackBar.info('Preferred ranges are saved successfully');
      });
  }

  clearEmptyRanges() {
    const { value } = this.petalForm;

    let ranges = [];
    value.petals.forEach((petal) => {
      petal.ranges.forEach((range) => {
        if (range.description !== '') {
          ranges.push({
            petalId: petal.id,
            ...range,
          });
        }
      });
    });

    return ranges;
  }

  getValuesRange(petal: any, order: number) {
    const range = petal.ranges.find((range) => range.order === order);
    if (range) {
      return range;
    }

    return null;
  }
}
