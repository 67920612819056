<div class="container">
  <form [formGroup]="petalForm">
    <h1>Preferred Ranges</h1>
    <button mat-raised-button color="primary" type="submit" (click)="onSave()">
      <mat-icon>save_alt</mat-icon>Save
    </button>
    <table>
      <colgroup>
        <col style="width: 10rem" />
        <col class="range" />
        <col class="range" />
        <col class="range" />
        <col class="range" />
        <col class="range" />
      </colgroup>
      <thead>
        <tr>
          <th class="title"></th>
          <th colspan="5" class="title">Preferred Ranges</th>
        </tr>
        <tr>
          <th class="empty-border-top title">Petals</th>
          <th>From - To</th>
          <th>From - To</th>
          <th>From - To</th>
          <th>From - To</th>
          <th>From - To</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let petalControl of petalsFormArray.controls; let i = index" [formGroup]="petalControl">
          <td>
            <p>{{ petalControl.get('label').value }}</p>
          </td>
          <td
            *ngFor="let rangeControl of petalControl.get('ranges').controls; let j = index"
            [formGroup]="rangeControl"
          >
            <div class="range">
              <mat-form-field>
                <mat-label>From</mat-label>
                <input matInput type="number" min="0" required formControlName="min" />
              </mat-form-field>
              <mat-form-field>
                <mat-label>To</mat-label>
                <input matInput type="number" min="0" required formControlName="max" />
              </mat-form-field>
              <button
                *ngIf="rangeControl.get('description').value === ''"
                mat-flat-button
                color="primary"
                (click)="openAddTextDialog(addTextForRangeDialog, rangeControl.get('description'), j)"
              >
                Add Text
              </button>
              <p
                *ngIf="rangeControl.get('description').value !== ''"
                (click)="openAddTextDialog(addTextForRangeDialog, rangeControl.get('description'), j)"
                [matTooltip]="
                  rangeControl.get('description').value.length > 15 ? rangeControl.get('description').value : ''
                "
              >
                {{ rangeControl.get('description').value | truncateNew: 15 }}
              </p>
              <button
                *ngIf="rangeControl.get('description').value !== ''"
                mat-flat-button
                color="primary"
                (click)="removeTextDialog(rangeControl.get('description'), j)"
              >
                Remove Text
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </form>

  <ng-template #addTextForRangeDialog>
    <h1 mat-dialog-title>Write text</h1>
    <mat-divider></mat-divider>
    <mat-form-field appearance="outline" style="width: 70vw">
      <textarea
        matInput
        type="text"
        [formControl]="addTextEditorControl"
        rows="5"
        cdkTextareaAutosize
        cdkAutosizeMinRows="5"
        cdkAutosizeMaxRows="auto"
      ></textarea>
    </mat-form-field>
    <div align="end" mat-dialog-actions>
      <button mat-flat-button (click)="closeAddTextDialog()">
        <mat-icon>cancel</mat-icon>
        Cancel
      </button>
      <button mat-flat-button [disabled]="addTextEditorControl.invalid" color="primary" (click)="saveAddTextDialog()">
        <mat-icon>save_alt</mat-icon>
        Save
      </button>
    </div>
  </ng-template>
</div>
