<div class="container-fluid">
  <span *ngIf="chart" class="psb-page-navigator"
    >Chart > <b>{{ chart.name }}</b> > {{ tabs[selected] }}</span
  >

  <mat-tab-group
    *ngIf="chart"
    mat-align-tabs="start"
    [selectedIndex]="selected"
    (selectedIndexChange)="tabChange($event)"
  >
    <ng-container *ngIf="['CIRCLE_IN_CARTESIAN', 'HEXAGON', 'FOUR_PLUS_ONE_CARTESIAN'].includes(chart?.base)">
      <mat-tab label="Details">
        <button mat-raised-button color="primary" class="edit-button" (click)="onEdit()">
          <mat-icon>edit</mat-icon>
          Edit
        </button>

        <div class="row">
          <div class="column">
            <span class="label">Name</span>
            <p>{{ chart.name }}</p>
            <span class="label">Header</span>
            <p>{{ chart.header }}</p>
            <span class="label">Base</span>
            <p>{{ chart.base }}</p>
            <span class="label">Plot</span>
            <p>{{ chart.plot }}</p>
            <span class="label">Area Mapping</span>
            <p>
              <a [routerLink]="chart.areaIndexId ? ['/area-index', chart.areaIndexId] : []">
                {{ chart.areaIndexName || 'Not specified' }}
              </a>
            </p>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Base Setup">
        <app-chart-base-setup [chart]="chart"></app-chart-base-setup>
      </mat-tab>

      <mat-tab label="Plot Setup">
        <app-chart-plot-setup [chart]="chart"></app-chart-plot-setup>
      </mat-tab>

      <mat-tab label="Subscales">
        <app-chart-subscales [chartId]="chartId" [chartBase]="chart?.base"></app-chart-subscales>
      </mat-tab>
    </ng-container>

    <ng-container *ngIf="['BLOSSOM_GRAPH'].includes(chart.base)">
      <mat-tab label="Details">
        <ng-template matTabContent>
          <app-blossom-chart-setup [chartId]="chart.id"></app-blossom-chart-setup>
        </ng-template>
      </mat-tab>
      <mat-tab label="Petals">
        <ng-template matTabContent>
          <app-blossom-petal-setup [chart]="chart"></app-blossom-petal-setup>
        </ng-template>
      </mat-tab>
      <mat-tab label="Pistils">
        <ng-template matTabContent>
          <app-blossom-pistil-setup [chart]="chart"></app-blossom-pistil-setup>
        </ng-template>
      </mat-tab>
      <mat-tab label="Petal Ranges">
        <ng-template matTabContent>
          <app-blossom-chart-petal-ranges [chart]="chart"></app-blossom-chart-petal-ranges>
        </ng-template>
      </mat-tab>
      <mat-tab label="Pistil Ranges">
        <ng-template matTabContent>
          <app-blossom-chart-pistil-ranges [chart]="chart"></app-blossom-chart-pistil-ranges>
        </ng-template>
      </mat-tab>
    </ng-container>
  </mat-tab-group>
</div>
