import { AfterViewInit, Component, Input, OnInit, ViewChild, AfterViewChecked } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

// components
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';

// services
import { SendEmailService } from '@app/core/services/send-email.service';

// models
import { EmailLog } from '@app/core/models/email-log.model';

@Component({
  selector: 'app-email-logs',
  templateUrl: './email-logs.component.html',
  styleUrls: ['./email-logs.component.scss'],
})
export class EmailLogsComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @Input() roleMandateId: number;
  @Input() connectionIndicatorId: number;
  @Input() testGroupId: number;
  @ViewChild('sortLogs') sortLogs: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;

  emailLogs = new MatTableDataSource<EmailLog>();
  displayedLogColumns: string[] = ['toEmail', 'sentAt', 'emailType', 'actions'];
  inviteAlreadySent = false;

  constructor(private sendEmailService: SendEmailService, public dialog: MatDialog) {}

  ngOnInit(): void {
    this.loadEmailLogs();
  }

  loadEmailLogs() {
    if (this.roleMandateId) {
      this.sendEmailService.getAllEmailLogsByRoleMandateId(this.roleMandateId).subscribe((logs) => {
        this.emailLogs.data = logs;
      });
    } else if (this.connectionIndicatorId) {
      this.sendEmailService.getAllEmailLogsByConnectionIndicatorId(this.connectionIndicatorId).subscribe((logs) => {
        this.emailLogs.data = logs;
      });
    } else if (this.testGroupId) {
      this.sendEmailService.getAllEmailLogsByTestGroupId(this.testGroupId).subscribe((logs) => {
        this.emailLogs.data = logs;
      });
    }
    this.emailLogs.paginator = this.paginator;
  }

  applyFilterInvites(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.emailLogs.filter = filterValue.trim().toLowerCase();

    if (this.emailLogs.paginator) {
      this.emailLogs.paginator.firstPage();
    }
  }

  showContent(email) {
    this.sendEmailService.getContentByEmailLogId(email.id).subscribe((result) => {
      let replacedEmailContent;
      if (result.content.includes('assets/images/psynet_logo.jpg')) {
        replacedEmailContent = result.content.replace(
          'src="assets/images/psynet_logo.jpg"',
          'src="https://psybil.io/assets/images/psynet_logo.jpg"',
        );
      }

      const contentDialog = this.dialog.open(InfoDialogComponent);
      contentDialog.componentInstance.title = email.toEmail + ' at ' + email.sentAt;
      if (email.sentUserName) {
        contentDialog.componentInstance.title =
          contentDialog.componentInstance.title + ' Sent by ' + email.sentUserName;
      }
      contentDialog.componentInstance.message = replacedEmailContent ? replacedEmailContent : result.content;
      contentDialog.componentInstance.isHtml = true;
    });
  }

  ngAfterViewInit() {
    this.emailLogs.paginator = this.paginator;
    this.emailLogs.sort = this.sortLogs;
  }

  ngAfterViewChecked() {
    this.sendEmailService.getInviteSentListener().subscribe((inviteSent) => {
      if (inviteSent && !this.inviteAlreadySent) {
        this.loadEmailLogs();
        this.inviteAlreadySent = true;
      }
    });
  }
}
