import { Component, Input, OnInit } from '@angular/core';
import { EVENT_SUCCESS } from '@app/core/constants';
import { MatDialog } from '@angular/material/dialog';
import { BlossomChartSetupFormComponent } from '@app/admin/pages/chart/blossom/blossom-chart-setup/blossom-chart-setup-form/blossom-chart-setup-form.component';

import { ChartController } from 'src/generated/v3/services/ChartController';
import { Chart } from 'src/generated/v3';

@Component({
  selector: 'app-blossom-chart-setup',
  templateUrl: './blossom-chart-setup.component.html',
  styleUrls: ['./blossom-chart-setup.component.scss'],
})
export class BlossomChartSetupComponent implements OnInit {
  @Input() chartId: number;

  blossomChart: Chart;

  constructor(private dialog: MatDialog, private chartController: ChartController) {}

  ngOnInit(): void {
    this.chartController.chartControllerGetBlossomChart(this.chartId).subscribe((blossomChart) => {
      this.blossomChart = blossomChart;
    });
  }

  onEdit() {
    const data = { chart: this.blossomChart };
    this.dialog
      .open(BlossomChartSetupFormComponent, { data })
      .afterClosed()
      .subscribe((result) => {
        if (result.event === EVENT_SUCCESS) {
          this.blossomChart = result.updatedChart;
        }
      });
  }
}
