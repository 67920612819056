<div *ngIf="visible" class="row">
  <div [ngClass]="chart && chart.subscales.length > 0 ? 'column' : 'row'">
    <div *ngIf="visible" [ngClass]="chart && chart.subscales.length > 0 ? '' : 'side-by-side'" class="image-block">
      <img [src]="src" alt="" class="image" />
    </div>
    <div
      [ngClass]="chart && chart.subscales.length > 0 ? '' : 'side-by-side vertical-center'"
      *ngIf="description"
      [innerText]="description"
      class="description"
    ></div>
  </div>
  <div class="column subcales" *ngIf="userId">
    <app-bar-subscales-new *ngIf="chart" [chartId]="chartId" [userId]="userId"></app-bar-subscales-new>
  </div>
</div>
