<div *ngIf="chapter.optimalScore">
  <div class="row page-list-header">
    <h2 [attr.id]="'toc-chapter-' + chapter.id" *ngIf="chapter.title">{{ chapter.title }}</h2>
    <app-optimal-score
      [chartId]="'chapter' + chapter.id"
      [score]="chapter.optimalScore.score.toFixed(2)"
      [size]="'medium'"
    >
    </app-optimal-score>
  </div>

  <mat-divider class="optimal-score-line"></mat-divider>

  <div>
    <h2 class="second-tier-optimal-scores-heading">{{ chapter.secondTierOptimalScoresHeading }}</h2>
  </div>

  <div class="section-optimal-score-list-header">
    <div *ngFor="let section of chapter.sections">
      <div
        *ngIf="
          ((section.sentences && section.sentences.length) || section.type === 'Scorecards') && section.optimalScore
        "
      >
        <app-optimal-score
          [chartId]="'section' + section.sectionOptimalScoreIndex"
          [score]="section.optimalScore.score.toFixed(2)"
          [description]="section.title"
          [size]="'between-medium-and-small'"
        >
        </app-optimal-score>
      </div>
      <div
        *ngIf="
          ((section.sentences && section.sentences.length) || section.type == 'Scorecards') &&
          !section.optimalScore &&
          chapter.numberOfSectionOptimalScores < 4
        "
      >
        <app-optimal-score
          [chartId]="'section' + section.sectionOptimalScoreIndex"
          [description]="section.title"
          [size]="'between-medium-and-small'"
        >
        </app-optimal-score>
      </div>
    </div>
  </div>
</div>

<h2 [attr.id]="'toc-chapter-' + chapter.id" *ngIf="chapter.title && !chapter.optimalScore">{{ chapter.title }}</h2>

<app-section
  *ngFor="let section of chapter.sections"
  [section]="section"
  [userName]="userName"
  [userInfoId]="userInfoId"
  [testGroupId]="testGroupId"
></app-section>
