import { Component, Input, OnInit } from '@angular/core';
import { BlossomChartPetalReport, BlossomChartPistilReport, BlossomChartService } from '../../../../../generated/v2';
import { ChartController } from 'src/generated/v3/services/ChartController';
import { Chart } from 'src/generated/v3';

@Component({
  selector: 'app-blossom',
  templateUrl: './blossom.component.html',
  styleUrls: ['./blossom.component.scss'],
})
export class BlossomComponent implements OnInit {
  @Input() chartId: number;

  @Input() testGroupId: number;

  @Input() userInfoId: number;

  blossomChart: Chart;

  petals: BlossomChartPetalReport[] = [];

  constructor(private blossomChartService: BlossomChartService, private chartController: ChartController) {}

  ngOnInit(): void {
    this.chartController.chartControllerGetBlossomChart(this.chartId).subscribe((blossomChart) => {
      this.blossomChart = blossomChart;
    });

    this.blossomChartService
      .getReportBlossomChartTestGroupUserChart(this.testGroupId, this.userInfoId, this.chartId)
      .subscribe((petals) => {
        this.petals = petals;
      });
  }

  legend() {
    const pistils: BlossomChartPistilReport[] = [];

    this.petals.forEach((petal) =>
      petal.pistils.forEach((pistil) => {
        if (!pistils.some((p) => p.color === pistil.color && p.label === pistil.label)) {
          pistils.push(pistil);
        }
      }),
    );

    return pistils;
  }
}
