import {
  AfterViewInit,
  Component,
  ViewChild,
  Inject,
  OnInit,
  AfterViewChecked,
  ChangeDetectionStrategy,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

// components
import { SubsectionFormComponent } from '@app/core/models/subsection-form-component';

// services
import { SectionService } from '@app/core/services/section.service';
import { ChapterService } from '@app/core/services/chapter.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';
import { ChartService } from '@app/core/services/chart.service';

// models
import { Section } from '@app/core/models/section.model';

// constants
import { EVENT_SUCCESS, EVENT_CANCEL } from '@app/core/constants';
import { environment } from '../../../../../environments/environment';
import { PsyChart } from '@app/core/models/psy-chart.model';

@Component({
  selector: 'app-add-section-dialog',
  templateUrl: './section-dialog-form.component.html',
  styleUrls: ['./section-dialog-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionDialogFormComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @ViewChild('subsectionForm') subsectionFormComponent: SubsectionFormComponent;

  form = this.fb.group({
    option: ['existing', [Validators.required]],
    section: ['', [Validators.required]],
    title: ['', [Validators.required]],
    type: ['', [Validators.required]],
    chart: null,
  });

  sections: any[];
  sectionFieldUpdate = new Subject<string>();
  sectionField: string;
  sectionTypes: { [key: string]: any[] } = {};
  types = ['Paragraph', 'BulletPoint', 'Chart', 'OrdinalParagraph', 'AllCombinationsParagraph', 'Scorecards'];
  autoSaveTimer: any;
  sectionTitle: string;
  isSaved = false;
  charts: PsyChart[] = [];

  constructor(
    private fb: FormBuilder,
    private chapterService: ChapterService,
    @Inject(MAT_DIALOG_DATA)
    public data: { chapterId: number; sectionIds: number[]; isEdit: boolean; section: Section; reportId?: number },
    private dialogRef: MatDialogRef<SectionDialogFormComponent>,
    private sectionService: SectionService,
    private snackBar: SnackBarService,
    private chartService: ChartService,
  ) {
    this.sectionFieldUpdate.pipe(debounceTime(400), distinctUntilChanged()).subscribe((value) => {
      if (typeof value === 'string') {
        this.sectionField = value;
      }
    });
  }

  ngOnInit(): void {
    if (this.data.isEdit && this.data.section) {
      this.form.get('type').setValue(this.data.section.type);
      this.form.get('title').setValue(this.data.section.title);
      this.form.get('option').setValue('edit');
      this.form.get('chart').setValue(this.data.section?.chartId);
      this.sectionTitle = this.data.section.title;
    } else {
      this.chapterService.getAllSections().subscribe((sections) => {
        this.sections = sections.filter((section) => !this.data.sectionIds.includes(section.id));
      });
    }
    this.chartService.getAll().subscribe((charts) => {
      this.charts = charts;
    });
  }

  displaySection(section: Section): string {
    return section ? section.title : '';
  }

  get isInvalidForm() {
    const { option } = this.form.value;

    return (
      !option ||
      (option === 'existing' && this.form.get('section').invalid) ||
      (option === 'new' && (this.form.get('title').invalid || this.form.get('type').invalid))
    );
  }

  sectionTypesEntries() {
    return Object.entries(this.sectionTypes);
  }

  onSubmit(close: boolean = false) {
    const { value } = this.form;
    if (value.option === 'existing') {
      this.chapterService.addSection(this.data.chapterId, this.form.value.section.id).subscribe((newSection) => {
        this.dialogRef.close({ event: EVENT_SUCCESS, newSection });
      });
    } else {
      const subsection = this.subsectionFormComponent.getSubsection();

      const body = {
        title: subsection.heading,
        type: value.type,
        subsection,
        chartId: value.chart || null,
      };

      if (value.option === 'new') {
        this.sectionService.create(body).subscribe((newSection) => {
          this.chapterService.addSection(this.data.chapterId, newSection.id).subscribe(() => {
            if (close) {
              this.dialogRef.close({ event: EVENT_SUCCESS, newSection });
            }
            this.data.isEdit = true;
            this.sectionTitle = newSection.title;
            this.data.section = newSection;
            this.isSaved = true;
            this.form.get('type').patchValue(newSection.type);
            this.form.get('title').patchValue(newSection.title);
            this.form.get('option').patchValue('edit');
            this.snackBar.info('Section has been saved.');
          });
        });
        return;
      } else if (value.option === 'edit') {
        this.sectionService.update(this.data.section.id, body).subscribe((updatedSection: Section) => {
          this.sectionService
            .findById(this.data.section.id, this.data.reportId, this.data.chapterId)
            .subscribe((section: Section) => {
              this.data.section = section;
              this.isSaved = true;
              this.snackBar.info('Section has been updated.');
              if (close) {
                this.dialogRef.close({ event: EVENT_SUCCESS, updatedSection });
              }
            });
        });
      }
    }
  }

  onCancel() {
    this.dialogRef.close({ event: EVENT_CANCEL, isSaved: this.isSaved });
  }

  ngAfterViewInit(): void {
    if (this.data.isEdit) {
      const stepperHeader = document.querySelector<HTMLElement>('.mat-horizontal-stepper-header');
      if (stepperHeader) {
        stepperHeader.style.display = 'none';
      }
    }
  }

  ngAfterViewChecked(): void {
    if (this.data.isEdit) {
      const stepperHeader = document.querySelector<HTMLElement>('.mat-horizontal-stepper-header');
      if (stepperHeader) {
        stepperHeader.style.display = 'none';
      }
    }

    if (!environment.production) {
      const { value } = this.form;

      clearTimeout(this.autoSaveTimer);
      this.autoSaveTimer = setTimeout(() => {
        const subsection = this.subsectionFormComponent.getSubsection();

        const body = {
          title: subsection.heading,
          type: value.type,
          subsection,
          chartId: value.chart || null,
        };

        if (value.option === 'new') {
          // Ignore auto save for new sections, as these are not saved yet
          // Please ask business if we need to save new sections then uncomment this
          //
          // this.sectionService.create(body).subscribe((newSection) => {
          //   this.chapterService.addSection(this.data.chapterId, newSection.id).subscribe(() => {
          //     this.data.isEdit = true;
          //     this.sectionTitle = newSection.title;
          //     this.data.section = newSection;
          //     this.isSaved = true;
          //     this.form.get('type').patchValue(newSection.type);
          //     this.form.get('title').patchValue(newSection.title);
          //     this.form.get('option').patchValue('edit');
          //
          //     this.sectionService.setAutoSaveSectionListener(newSection);
          //     this.snackBar.info('Section has been automatically saved.');
          //   });
          // });
        } else if (value.option === 'edit') {
          this.sectionService.update(this.data.section.id, body).subscribe((updatedSection: Section) => {
            this.sectionTitle = updatedSection.title;
            this.sectionService.setAutoSaveSectionListener(updatedSection);
            this.snackBar.info('This section has been automatically saved.');
          });
        }
      }, 60000);
    }
  }
}
