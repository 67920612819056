<div class="chartPanel pdf-page" *ngFor="let chart of charts; let i = index">
  <h2 id="toc-charts" *ngIf="i === 0">Charts</h2>
  <h3 [attr.id]="'toc-chart-' + chart.id">{{ chart.header }}</h3>
  <div [ngSwitch]="chart.base">
    <app-blossom
      *ngSwitchCase="'BLOSSOM_GRAPH'"
      [chartId]="chart.id"
      [userInfoId]="this.userId"
      [testGroupId]="testGroupId"
    ></app-blossom>

    <app-hexagon
      *ngSwitchCase="'HEXAGON'"
      [visible]="chart.visible"
      [chartId]="chart.id"
      [userId]="this.userId"
      [testGroupId]="testGroupId"
    >
    </app-hexagon>

    <app-square-plot
      *ngSwitchCase="'CIRCLE_IN_CARTESIAN'"
      [visible]="chart.visible"
      [chartId]="chart.id"
      [userId]="this.userId"
      [testGroupId]="testGroupId"
    >
    </app-square-plot>

    <app-circular-plot-new
      *ngSwitchCase="'FOUR_PLUS_ONE_CARTESIAN'"
      [visible]="chart.visible"
      [chartId]="chart.id"
      [userId]="userId"
      [testGroupId]="testGroupId"
    >
    </app-circular-plot-new>
  </div>
</div>
<div
  class="pdf-page"
  *ngIf="updatedSuperScaleScoreCards && updatedSuperScaleScoreCards.length > 0 && report.scorecards"
>
  <h2 id="toc-scorecards">Scorecards</h2>
  <div>
    <app-scorecards-table
      [scorecards]="updatedSuperScaleScoreCards"
      [customRanges]="customRanges"
      [typeOfPage]="'userReport'"
    ></app-scorecards-table>
  </div>
</div>

<div *ngFor="let chapter of report?.chapters" class="pdf-page">
  <app-chapter
    [chapter]="chapter"
    [userName]="user.firstName"
    [userInfoId]="user.id"
    [testGroupId]="testGroupId"
  ></app-chapter>
</div>

<div class="pdf-page" *ngIf="report?.testResults && report.testResults.length > 0">
  <h2 id="toc-assessment-results">Assessment Results</h2>
  <app-test-results-table [testResults]="report.testResults"></app-test-results-table>
</div>
