/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { TestGroupModel } from '../models/TestGroupModel';
import type { UpdateTestGroupInput } from '../models/UpdateTestGroupInput';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class TestGroupController {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param testGroupId
     * @param requestBody
     * @returns TestGroupModel
     * @throws ApiError
     */
    public testGroupControllerUpdateTestGroup(
        testGroupId: number,
        requestBody: UpdateTestGroupInput,
    ): Observable<TestGroupModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v3/test-group/{testGroupId}/update',
            path: {
                'testGroupId': testGroupId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
