<div
  class="mob-view"
  *ngIf="
    (section.sentences && section.sentences.length) ||
    section.type === 'Scorecards' ||
    section.type === 'AllCombinationsParagraph'
  "
>
  <h3 *ngIf="section.title">{{ section.title }}</h3>

  <div *ngIf="!!section?.chartId" [ngSwitch]="chart.base">
    <h3 [attr.id]="'toc-chart2-' + chart.id">{{ chart.header }}</h3>
    <app-blossom
      *ngSwitchCase="'BLOSSOM_GRAPH'"
      [chartId]="chart.id"
      [userInfoId]="userInfoId"
      [testGroupId]="testGroupId"
    ></app-blossom>

    <app-hexagon
      *ngSwitchCase="'HEXAGON'"
      [chartId]="chart.id"
      [userId]="userInfoId"
      [testGroupId]="testGroupId"
      [chartName]="chartName"
    >
    </app-hexagon>

    <app-square-plot
      *ngSwitchCase="'CIRCLE_IN_CARTESIAN'"
      [chartId]="chart.id"
      [userId]="userInfoId"
      [testGroupId]="testGroupId"
      [chartName]="chartName"
    >
    </app-square-plot>

    <app-circular-plot-new
      *ngSwitchCase="'FOUR_PLUS_ONE_CARTESIAN'"
      [chartId]="chart.id"
      [userId]="userInfoId"
      [testGroupId]="testGroupId"
    >
    </app-circular-plot-new>
  </div>
  <div [ngSwitch]="section.type">
    <div [ngSwitch]="section.type">
      <div *ngSwitchCase="'Paragraph'">
        <app-paragraph [model]="section"></app-paragraph>
      </div>
      <div *ngSwitchCase="'BulletPoint'">
        <app-bullet-point [model]="section"></app-bullet-point>
      </div>
      <div *ngSwitchCase="'OrdinalParagraph'">
        <app-ordinal-paragraph [model]="section" [showBottom]="true"></app-ordinal-paragraph>
      </div>
      <div *ngSwitchCase="'AllCombinationsParagraph'">
        <app-combination-paragraph [model]="section"></app-combination-paragraph>
      </div>
      <div *ngSwitchCase="'Chart'">
        <app-chart-section [model]="section" [userName]="userName"></app-chart-section>
      </div>
      <div *ngSwitchCase="'Scorecards'">
        <app-scorecards-table [scorecards]="section.scorecards"></app-scorecards-table>
      </div>
    </div>
    <br />
  </div>
</div>
<div
  class="mob-view"
  *ngIf="
    (!section.sentences || (section.sentences && section.sentences.length === 0)) &&
    section.type !== 'Scorecards' &&
    section.type !== 'AllCombinationsParagraph'
  "
>
  <div [ngSwitch]="section.type">
    <div [ngSwitch]="section.type">
      <div *ngSwitchCase="'Paragraph'">
        <div *ngIf="section.defaultSentenceEnabled">
          <h2 *ngIf="section.title" class="r-h2">{{ section.title }}</h2>
          <div [innerHTML]="section.defaultSentence"></div>
        </div>
      </div>
      <div *ngSwitchCase="'BulletPoint'">
        <div *ngIf="section.defaultSentenceEnabled">
          <h2 *ngIf="section.title" class="r-h2">{{ section.title }}</h2>
          <div [innerHTML]="section.defaultSentence"></div>
        </div>
      </div>
      <div *ngSwitchCase="'OrdinalParagraph'">
        <div *ngIf="section.defaultSentenceEnabled">
          <h2 *ngIf="section.title" class="r-h2">{{ section.title }}</h2>
          <div *ngIf="section.topDefaultSentence" [innerHTML]="section.topDefaultSentence"></div>
          <div *ngIf="section.bottomDefaultSentence" [innerHTML]="section.bottomDefaultSentence"></div>
        </div>
      </div>
      <div *ngSwitchCase="'AllCombinationsParagraph'">
        <div *ngIf="section.defaultSentenceEnabled">
          <h2 *ngIf="section.title" class="r-h2">{{ section.title }}</h2>
          <div [innerHTML]="section.doubleRangesDefaultSentence"></div>
        </div>
      </div>
      <div *ngSwitchCase="'Chart'">
        <div *ngIf="section.defaultSentenceEnabled">
          <h2 *ngIf="section.title" class="r-h2">{{ section.title }}</h2>
          <div [innerHTML]="section.doubleRangesDefaultSentence"></div>
        </div>
        <div *ngIf="section.agilitySentence" [innerHTML]="section.agilitySentence.text"></div>
        <div *ngIf="!section.agilitySentence && section.defaultSentenceEnabled">
          <div [ngSwitch]="section.agilityType">
            <div *ngSwitchCase="'above50'">
              <div [innerHTML]="section.agilityBothAbove50DefaultSentence"></div>
            </div>
            <div *ngSwitchCase="'bellow50'">
              <div [innerHTML]="section.agilityBothBelow50DefaultSentence"></div>
            </div>
            <div *ngSwitchCase="'aboveandbellow50'">
              <div [innerHTML]="section.agilityOneAbove50OneBelow50DefaultSentence"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
  </div>
</div>
