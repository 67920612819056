import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-all-combination-paragraph',
  templateUrl: './all-combination-paragraph.component.html',
  styleUrls: ['./all-combination-paragraph.component.scss'],
})
export class AllCombinationParagraphComponent implements OnInit {
  @Input() combinationParagraph: any;
  @Input() leadSentence: string;
  @Input() chart: any;

  constructor() {}

  ngOnInit(): void {
    this.leadSentence =
      this.combinationParagraph.leadSentence.he !== undefined
        ? this.combinationParagraph.leadSentence.he !== 'null'
          ? this.combinationParagraph.leadSentence.he
          : ''
        : '';
  }
}
