import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bar-subscales-new',
  templateUrl: './bar-subscales.component.html',
  styleUrls: ['./bar-subscales.component.scss'],
})
export class BarSubscalesComponent implements OnInit {
  @Input() chartId: number;
  @Input() userId: number;

  src: string;

  ngOnInit(): void {
    this.src = `${environment.apiUrl}/v3/user/${this.userId}/chart/${this.chartId}/bar-subscales-chart.png`;
    console.log(this.src);
  }
}
