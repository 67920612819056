<div class="container">
  <div class="text-center">
    <h2>
      We are either working on something really cool or performing a less interesting system update. Please try again
      later or contact your Systems Administrator at admin@psynetgroup.com. We apologize for this inconvenience.
    </h2>
  </div>
  <div class="image-center">
    <img src="https://psybil.io/assets/images/psynet_logo.jpg" width="250px" height="250px" alt="logo" />
  </div>
  <button
    *ngIf="userIsAuthenticated"
    mat-raised-button
    class="btn-success btn-center btn-300"
    id="log-in-submit"
    (click)="onLogout()"
    i18n="Logout|Log out@@logout"
  >
    Log out
  </button>
</div>
