/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import { BaseHttpRequest } from '../core/BaseHttpRequest';

@Injectable()
export class BarSubscalesController {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param userId
     * @param chartId
     * @returns string
     * @throws ApiError
     */
    public barSubscalesControllerCircularChartPng(
        userId: number,
        chartId: number,
    ): Observable<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v3/user/{userId}/chart/{chartId}/bar-subscales-chart.png',
            path: {
                'userId': userId,
                'chartId': chartId,
            },
        });
    }

}
