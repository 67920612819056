import { AfterViewInit, Component, Input } from '@angular/core';
import * as d3 from 'd3';
import * as d3plus from 'd3';

@Component({
  selector: 'app-bar-subscales',
  templateUrl: './bar-subscales.component.html',
  styleUrls: ['./bar-subscales.component.scss'],
})
export class BarSubscalesComponent implements AfterViewInit {
  @Input() subcales: any[];
  @Input() chartId: number;
  @Input() chartName?: string = '1';

  margin = { top: 10, right: 40, bottom: 30, left: 200 };
  width: number;
  height: number;
  svg: any;
  viewBox: any;

  lollipopLine = '#66809a';
  userColor = '#F1592A'; // color for user
  pageWidth: any;

  constructor() {
    this.width = 500 - this.margin.left - this.margin.right;
    this.height = 280 - this.margin.top - this.margin.bottom;
    this.pageWidth = window.screen.width;
    this.viewBox = '0 0 ' + (this.pageWidth < 490 ? 700 : 500) + ' ' + 280;
  }

  responsiveLollipop() {
    if (this.pageWidth < 390) return { width: 400, height: 180 };
  }

  ngAfterViewInit() {
    this.svg = d3
      .select('figure#bar-subcales' + this.chartId + this.chartName)
      .append('svg')
      .attr('viewBox', this.viewBox)
      .attr(
        'width',
        this.responsiveLollipop()?.width
          ? this.responsiveLollipop().width
          : this.width + (this.margin.left + this.margin.right),
      ) // this.width + (this.margin.left + this.margin.right))
      .attr(
        'height',
        this.responsiveLollipop()?.height
          ? this.responsiveLollipop().height
          : this.height + (this.margin.top + this.margin.bottom),
      ) // this.height + (this.margin.top + this.margin.bottom))
      .append('g')
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');

    // Add X axis
    const x = d3.scaleLinear().domain([0, 100]).range([0, this.width]);

    this.svg
      .append('g')
      .attr('transform', 'translate(0,' + this.height + ')')
      .call(d3.axisBottom(x))
      .call((g) => g.select('.domain').remove())
      .selectAll('text')
      // .attr('transform', 'translate(-10,0)rotate(-45)')
      .attr('fill', '#66809a')
      .attr('font-family', 'Arial')
      .attr('font-size', '10px')
      .style('text-anchor', 'end');

    const y = d3
      .scaleBand()
      .range([0, this.height])
      .domain(this.subcales.map((d) => d.subscaleTitle))
      .padding(1);

    this.svg
      .append('g')
      .call(d3.axisLeft(y))
      .call((g) => g.select('.domain').attr('stroke', '#66809a').attr('stroke-width', '3px'))
      .selectAll('text')
      .attr('fill', '#66809a')
      .attr('font-family', 'Arial')
      .attr('font-size', '15px');

    const line = this.svg
      .selectAll('myline')
      .data(this.subcales)
      .enter()
      .append('line')
      .attr('x1', x(0))
      .attr('x2', x(0))
      .attr('y1', (d) => y(d.subscaleTitle))
      .attr('y2', (d) => y(d.subscaleTitle))
      .attr('stroke', this.lollipopLine);

    const div = d3.select('body').append('div').attr('class', 'tooltip-chart').style('opacity', 0);

    this.svg
      .selectAll('.firstrow')
      .data(this.subcales)
      .enter()
      .append('circle')
      .attr('cx', (d, i) => x(0))
      .attr('cy', (d) => y(d.subscaleTitle))
      .attr('r', 13)
      .attr('fill', this.userColor)
      .on('mouseover', (event, d) => {
        div
          .html(`${d.percentile ? d.percentile : 0}%`)
          .style('left', event.pageX + 10 + 'px')
          .style('top', event.pageY - 15 + 'px');

        // Makes the new div appear on hover:
        div.transition().duration(50).style('opacity', 1);
      })
      .on('mouseout', (event, d) => {
        d3.select(event.currentTarget).transition().duration(50).attr('opacity', '1');
        // Makes the new div disappear:
        div.transition().duration(50).style('opacity', 0);
      });

    this.svg
      .selectAll('line')
      .transition()
      .duration(2000)
      .attr('x1', (d) => x(d.percentile));

    this.svg
      .selectAll('circle')
      .data(this.subcales)
      .transition()
      .duration(2000)
      .attr('cx', (d) => x(d.percentile))
      .attr('r', (d) => x(2 + d.percentile * 0.05));

    this.svg.selectAll('text').each(function () {
      const text = d3.select(this);
      const words = text.text().split(/\s+/).reverse();
      let word;
      // tslint:disable-next-line:no-shadowed-variable
      let line = [];
      // tslint:disable-next-line:no-shadowed-variable
      let lineNumber = 0;
      const lineHeight = 0.5; // ems
      // tslint:disable-next-line:no-shadowed-variable
      const y = text.attr('y');
      // tslint:disable-next-line:no-shadowed-variable
      const dy = parseFloat(text.attr('dy'));
      let tspan = text.text(null).append('tspan');
      // tslint:disable-next-line:no-conditional-assignment
      while ((word = words.pop())) {
        line.push(word);
        tspan.text(line.join(' '));
        if (tspan.node().getComputedTextLength() > 200) {
          line.pop();
          tspan.text(line.join(' '));
          line = [word];
          tspan = text
            .append('tspan')
            .attr('x', -9)
            .attr('y', y)
            .attr('dy', `${++lineNumber * lineHeight + dy}em`)
            .text(word);
        }
      }
    });
  }
}
