import { OnChanges, AfterViewInit, Component, Input, Output, EventEmitter } from '@angular/core';
import * as d3 from 'd3';
import * as d3Shape from 'd3-shape';
import { forkJoin } from 'rxjs';

// services
import { ChartService } from '@app/core/services/chart.service';
import { UserService } from '@app/core/services/user.service';

// helpers
import { ChartHelper } from '@app/core/helpers/chart-helper';
import { environment } from 'src/environments/environment';
import { AreaIndexService } from '@app/core/services/area-index.service';
import { UtilsService } from '@app/core/services/utils.service';
import { TestGroupChartComparison } from '@app/core/models/test-group-chart-comparison.model';
import { ReportService } from '@app/core/services/report.service';

@Component({
  selector: 'app-hexagon',
  templateUrl: './hexagon.component.html',
  styleUrls: ['./hexagon.component.scss'],
})
export class HexagonComponent extends ChartHelper implements OnChanges, AfterViewInit {
  @Input() chartId: number;
  @Input() userId?: number;
  @Input() testGroupId: number;
  @Input() companyId?: number;
  @Input() chartSelectedUsers: any[];
  @Input() visible?: boolean = true;
  @Input() chartName?: string = '1';

  userColor = '#F1592A'; // color for user
  mainColor = '#425465'; // color for main wave
  rectColor = 'white'; // color for graph division lines
  groupColor = 'white'; // color for group dots
  groupStroke = '#425465'; // stroke for group dots
  groupTextColor = '#4AC29A'; // color for user name or id text
  contourColor = 'white'; // color for contour
  textColor = '#66809a'; // color for graph text and Highlight ideal area of quadrant (topright)

  margin = { top: 50, right: 50, bottom: 50, left: 50 };
  width: number;
  height: number;
  svg: any;

  rScale: any;
  xAxis: any;
  yAxis: any;

  allUsers = [];
  selectedUsers = [];
  chart: any;
  description: string;
  gender: string;

  angleChoice = [0, 60, 120, 180, 240, 300];
  dirs = ['N', 'NE', 'SE', 'S', 'SW', 'NW'];
  pathNumber: any;
  user: any;
  constructor(
    private chartService: ChartService,
    private userService: UserService,
    private areaIndexService: AreaIndexService,
    private utilsService: UtilsService,
    private reportService: ReportService,
  ) {
    super();
    this.width = 500 - this.margin.left - this.margin.right;
    this.height = 500 - this.margin.top - this.margin.bottom;
  }

  ngAfterViewInit() {
    if (this.userId) {
      if (!this.companyId) {
        forkJoin([
          this.chartService.getResultsByTestGroup(this.chartId, this.testGroupId),
          this.chartService.getChartWithResultsByUser(this.chartId, this.userId),
          this.userService.get(this.userId),
        ]).subscribe((results) => {
          this.reportService
            .getAllTestGroupChartComparisonByTestGroupIdAndChartId(this.testGroupId, this.chartId)
            .subscribe((testGroupChartComparisons: TestGroupChartComparison[]) => {
              Object.keys(results[0]).forEach((user) => {
                if (
                  !testGroupChartComparisons.find(
                    (testGroupChartComparison: TestGroupChartComparison) =>
                      testGroupChartComparison.userInfoId === parseInt(user),
                  )
                ) {
                  delete results[0][user];
                }
              });
              this.allUsers = results[0];
              this.chart = results[1];
              // Urgent fix, need to be refacted in the future
              if (this.chart.subscales?.length === 0) {
                this.chart.superscales?.map((ss) => (ss.subscaleTitle = ss.title));
                this.chart.subscales = this.chart.superscales;
              }
              this.user = results[2];
              this.assignSelectedUsers();
              this.buildSvg();
              this.buildAxes();
              this.buildChart();
            });
        });
      } else {
        forkJoin([
          this.chartService.getResultsByCompany(this.chartId, this.companyId),
          this.chartService.getChartWithResultsByUser(this.chartId, this.userId),
          this.userService.get(this.userId),
        ]).subscribe((results) => {
          this.allUsers = results[0];
          this.chart = results[1];
          this.user = results[2];

          this.assignSelectedUsers();
          this.buildSvg();
          this.buildAxes();
          this.buildChart();
        });
      }
    } else {
      forkJoin([
        this.chartService.getResultsByTestGroup(this.chartId, this.testGroupId),
        this.chartService.getById(this.chartId),
      ]).subscribe((results) => {
        this.allUsers = results[0];
        this.chart = results[1];

        this.assignSelectedUsers();
        this.buildSvg();
        this.buildAxes();
        this.buildChart();
        this.chartService.setChartUsersListener(this.transformUsersData(this.allUsers));
      });
    }
  }

  ngOnChanges() {
    if (this.svg) {
      this.assignSelectedUsers();
      this.buildAxes();
      this.buildChart();
    }
  }

  private buildAxes() {
    // set up the x scale
    const xScale = d3.scaleLinear().domain([-100, 100]).range([20, this.width]); // actual length of axis

    // set up the y scale
    const yScale = d3
      .scaleLinear()
      .domain([-100, 100])
      .range([this.height - 25, 25]); // actual length of axis

    this.rScale = d3
      .scaleLinear()
      .domain([0, 100])
      .range([0, this.height - 3]);

    // define the x axis
    this.xAxis = d3.axisBottom(xScale); // location of lables (default is bottom)

    // define the y axis
    this.yAxis = d3.axisLeft(yScale);

    // create hexagon data path
    const s32 = Math.sqrt(3) / 2;
    const val = 200;
    const xDiff = 200;
    const yDiff = 200;
    const pointData = [
      [val + xDiff, 0 + yDiff],
      [val / 2 + xDiff, val * s32 + yDiff],
      [-val / 2 + xDiff, val * s32 + yDiff],
      [-val + xDiff, 0 + yDiff],
      [-val / 2 + xDiff, -val * s32 + yDiff],
      [val / 2 + xDiff, -val * s32 + yDiff],
    ];

    const line = d3Shape
      .line()
      .x((d: any) => xScale(d.x))
      .y((d: any) => yScale(d.y))
      .curve(d3Shape.curveCardinalClosed);

    // Clean up previous line path
    if (this.svg && !this.svg.selectAll('path').empty()) {
      this.svg.selectAll('path').remove();
    }

    // Configuring line path
    // this.svg.append('path').datum(this.allUsers).attr('d', line);

    this.svg
      .selectAll('path.area')
      .data([pointData])
      .enter()
      .append('path')
      .attr('d', d3.line())
      .attr('fill', this.mainColor)
      .attr('stroke', this.mainColor)
      .attr('stroke-width', '0.3px');

    // call the xAxis function to generate the x axis
    this.svg
      .append('g')
      .attr('class', 'axis') // assign an axis class
      .attr('transform', 'translate(0, ' + this.height / 2 + ')')
      .call(this.xAxis);

    // call the yAxis function to generate the x axis
    this.svg
      .append('g')
      .attr('class', 'axis') // assign an axis class
      .attr('transform', 'translate(' + this.width / 2 + ', 0)')
      .call(this.yAxis);

    this.svg
      .append('rect')
      .attr('x', 0)
      .attr('y', 200)
      .attr('width', 400)
      .attr('height', 2)
      .style('fill', this.rectColor);

    this.svg
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', 400)
      .attr('height', 2)
      .style('fill', this.rectColor)
      .attr('transform', 'translate(101 27) rotate(60)');

    this.svg
      .append('rect')
      .attr('x', -120)
      .attr('y', 0)
      .attr('width', 400)
      .attr('height', 2)
      .style('fill', this.rectColor)
      .attr('transform', 'translate(240 131) rotate(120)');
  }

  private buildSvg() {
    this.svg = d3
      .select('figure#HEXAGON' + this.chartId + this.chartName)
      .append('svg')
      .attr('viewBox', '0 0 ' + 400 + ' ' + 400)
      .attr('max-height', '400')
      // .attr('height', this.height + (this.margin.top + this.margin.bottom))
      .append('g');
    // .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');
  }

  private buildChart() {
    const dataALL = this.selectedUsers;

    // set up the x scale
    const xScale = d3.scaleLinear().domain([-100, 100]).range([20, this.width]); // actual length of axis

    // set up the y scale
    const yScale = d3
      .scaleLinear()
      .domain([-100, 100])
      .range([this.height - 25, 25]); // actual length of axis
    const densityData = d3
      .contourDensity()
      .x((d) => xScale(d['x'])) // x and y = column name in .csv input data
      .y((d) => yScale(d['y']))
      .size([this.width, this.height])
      .bandwidth(35)
      .thresholds(60)(
      // smaller = more precision in lines = more lines
      dataALL,
    );

    // Add the contour: several "path"
    this.svg
      .selectAll('path')
      .data(densityData)
      .enter()
      .append('path')
      .attr('d', d3.geoPath())
      .attr('fill', 'none')
      .attr('stroke', this.contourColor)
      .attr('stroke-width', '0.019rem')
      .attr('stroke-linejoin', 'round');

    const div = d3.select('body').append('div').attr('class', 'tooltip-chart').style('opacity', 0);

    if (this.svg && !this.svg.selectAll('circle').empty()) {
      this.svg.selectAll('circle').remove();
    }

    this.svg
      .selectAll('dot')
      .data(dataALL)
      .enter()
      .append('circle')
      .attr('cx', (d) => xScale(0))
      .attr('cy', (d) => yScale(0))
      .attr('r', 3)
      .style('fill', this.groupColor)
      .style('stroke', this.groupStroke)
      .style('stroke-width', '0.5px')
      .on('mouseover', (event, d) => {
        d3.select(event.currentTarget).transition().duration(100).attr('r', 5);

        d3.select('body')
          .append('div')
          .html(d.userName)
          .attr('class', 'tooltip')
          .style('left', event.pageX + 15 + 'px')
          .style('top', event.pageY - 15 + 'px');
      })
      .on('mouseout', (event, d) => {
        d3.select(event.currentTarget).transition().duration(50).attr('opacity', 1).attr('r', 3);
        d3.selectAll('.tooltip').remove();
      });

    let a = 0;
    let b = 0;

    if (this.chart?.subscales.length) {
      let firstPercentile = this.chart.subscales[0]?.percentile;
      const angle = this.getAngle(this.chart.subscales[0]?.direction, this.chart.subscales[1]?.direction);

      firstPercentile = firstPercentile > 0 ? firstPercentile - 10 : 0;
      const fin1 = firstPercentile * Math.cos(this.deg2rad(angle));
      a = fin1;
      const fin = firstPercentile * Math.sin(this.deg2rad(angle));
      b = fin;
    }

    if (this.userId) {
      const userData = [
        {
          x: a,
          y: b,
          name: this.chart.user.userName,
        },
      ];

      this.svg
        .data(userData)
        .append('circle')
        .attr('cx', (d) => xScale(0))
        .attr('cy', (d) => yScale(0))
        .attr('r', 5)
        .style('fill', this.userColor)
        .on('mouseover', (event, d) => {
          d3.select(event.currentTarget).transition().duration(100).attr('r', 7);

          d3.select('body')
            .append('div')
            .html(d.name)
            .attr('class', 'tooltip')
            .style('left', event.pageX + 15 + 'px')
            .style('top', event.pageY - 15 + 'px');
        })
        .on('mouseout', (event, d) => {
          d3.select(event.currentTarget).transition().duration(50).attr('opacity', 1).attr('r', 5);
          d3.selectAll('.tooltip').remove();
        });
      this.getPathNumberByCoordinates(xScale(a), yScale(b));
    }

    this.svg
      .selectAll('circle')
      .transition()
      .delay((d, i) => i * 3)
      .duration(2000)
      .attr('cx', (d) => xScale(d.x))
      .attr('cy', (d) => yScale(d.y));

    this.svg
      .selectAll('dot')
      .transition()
      .delay((d, i) => i * 3)
      .duration(2000)
      .attr('cx', (d) => xScale(d.x))
      .attr('cy', (d) => yScale(d.y));

    this.addCopyright(this.svg, this.width / 2, this.height + this.margin.bottom - 3);

    this.chart.sextants?.forEach((sextant) => {
      switch (sextant.direction) {
        case 'N':
          this.svg
            .append('text')
            .attr('x', xScale(0) - this.utilsService.getWidth(sextant.title, 14, 'Arial') / 2)
            .attr('y', 20)
            .text(sextant.title)
            .style('font-size', 14)
            .style('font-family', 'Arial')
            .style('fill', this.textColor);
          break;
        case 'NE':
          this.svg
            .append('text')
            .attr('x', 275 - this.utilsService.getWidth(sextant.title, 14, 'Arial') / 2)
            .attr('y', -255)
            .style('font-size', 14)
            .text(sextant.title)
            .style('font-family', 'Arial')
            .style('fill', this.textColor)
            .attr('transform', 'rotate(60)');

          break;
        case 'SE':
          this.svg
            .append('text')
            .attr('x', -75 - this.utilsService.getWidth(sextant.title, 14, 'Arial') / 2)
            .attr('y', 465)
            .style('font-size', 14)
            .text(sextant.title)
            .style('font-family', 'Arial')
            .style('fill', this.textColor)
            .attr('transform', 'rotate(-60)');
          break;
        case 'S':
          this.svg
            .append('text')
            .attr('x', xScale(0) - this.utilsService.getWidth(sextant.title, 14, 'Arial') / 2)
            .attr('y', 390)
            .style('font-size', 14)
            .text(sextant.title)
            .style('font-family', 'Arial')
            .style('fill', this.textColor);
          break;
        case 'SW':
          this.svg
            .append('text')
            .attr('x', 275 - this.utilsService.getWidth(sextant.title, 14, 'Arial') / 2)
            .attr('y', 120)
            .style('font-size', 14)
            .text(sextant.title)
            .style('font-family', 'Arial')
            .style('fill', this.textColor)
            .attr('transform', 'rotate(60)');
          break;
        case 'NW':
          this.svg
            .append('text')
            .attr('x', -75 - this.utilsService.getWidth(sextant.title, 14, 'Arial') / 2)
            .attr('y', 90)
            .style('font-size', 14)
            .text(sextant.title)
            .style('font-family', 'Arial')
            .style('fill', this.textColor)
            .attr('transform', 'rotate(-60)');
          break;
        default:
          break;
      }
    });
  }

  deg2rad(degrees) {
    // convert degrees to radians
    return (Math.PI * degrees) / 180;
  }

  getAngle(firstChartSubscale, secondChartSubscale) {
    let angle = 0;
    switch (firstChartSubscale) {
      case 'NE':
        angle = 30;

        switch (secondChartSubscale) {
          case 'N':
            angle += 20;
            break;
          case 'SE':
            angle -= 20;
            break;
          default:
            break;
        }
        break;
      case 'N':
        angle = 90;

        switch (secondChartSubscale) {
          case 'NE':
            angle -= 20;
            break;
          case 'NW':
            angle += 20;
            break;
          default:
            break;
        }
        break;
      case 'NW':
        angle = 150;

        switch (secondChartSubscale) {
          case 'N':
            angle -= 20;
            break;
          case 'SW':
            angle += 20;
            break;
          default:
            break;
        }
        break;
      case 'SW':
        angle = 210;

        switch (secondChartSubscale) {
          case 'NW':
            angle -= 20;
            break;
          case 'S':
            angle += 20;
            break;
          default:
            break;
        }
        break;
      case 'S':
        angle = 270;

        switch (secondChartSubscale) {
          case 'SW':
            angle -= 20;
            break;
          case 'SE':
            angle += 20;
            break;
          default:
            break;
        }
        break;
      case 'SE':
        angle = 330;

        switch (secondChartSubscale) {
          case 'S':
            angle -= 20;
            break;
          case 'NE':
            angle += 20;
            break;
          default:
            break;
        }
        break;
    }
    return angle;
  }

  formulaDot(user, axis) {
    let firstPercentile = user[0].percentile;
    const angle = this.getAngle(user[0].direction, user[1].direction);
    firstPercentile = firstPercentile > 0 ? firstPercentile - 10 : 0;

    if (axis === 'y') {
      return firstPercentile * Math.sin(this.deg2rad(angle));
    } else {
      return firstPercentile * Math.cos(this.deg2rad(angle));
    }
  }

  assignSelectedUsers() {
    this.chartSelectedUsers
      ? (this.selectedUsers = this.chartSelectedUsers)
      : (this.selectedUsers = this.transformUsersData(this.allUsers));
  }

  transformUsersData(users: any[]) {
    let usersData = [];
    for (const userId in users) {
      if (users[userId]) {
        const user = users[userId];
        usersData.push({
          x: this.formulaDot(user, 'x'),
          y: this.formulaDot(user, 'y'),
          userName: user[0].userName,
          userInfoId: user[0].userInfoId,
        });
      }
    }

    for (const userData of usersData) {
      const sameCoordinates = usersData.filter((userData_) => userData_.x === userData.x && userData_.y === userData.y);
      const angle = 360 / sameCoordinates.length;

      for (let i = 1; i < sameCoordinates.length; i++) {
        userData.y += (i <= 7 ? 6 : 10) * Math.sin(angle * i);
        userData.x += (i <= 7 ? 6 : 10) * Math.cos(angle * i);
      }
    }

    return usersData;
  }

  private async getPathNumberByCoordinates(x, y) {
    d3.xml(`${environment.assetsUrl}/images/hexagon.svg`).then(async (response: any) => {
      document.querySelector('#tempBindHexagonPlot').appendChild(response.getElementById('hexagon_plot_svg'));

      let svg: any = document.getElementById('hexagon_plot_svg');
      let paths: any = svg.getElementsByTagName('path');
      let point: any = svg.createSVGPoint();
      point.x = x;
      point.y = y;

      for (let path of paths) {
        if (path.isPointInFill(point)) {
          this.pathNumber = path.getAttribute('number');
          break;
        }
      }
      document.querySelector('#tempBindHexagonPlot').innerHTML = '';
      let areaPathNumber = await this.areaIndexService.getAreaIndex(this.chart, this.pathNumber);
      if (areaPathNumber) {
        this.chart.area = areaPathNumber;
        this.gender = this.userService.getPronoun(this.chart.user);
        this.description = this.userService.getPronounDescription(this.chart, this.gender);
      } else {
        this.description = null;
      }
    });
  }
}
