<form [formGroup]="form">
  <mat-form-field>
    <mat-label>Heading</mat-label>
    <input matInput type="text" required formControlName="heading" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Optimal Summary Positive Heading</mat-label>
    <input matInput type="text" formControlName="positiveHeading" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Optimal Summary Concern Heading</mat-label>
    <input matInput type="text" formControlName="concernHeading" />
  </mat-form-field>
  <div class="lead-sentence">
    <mat-form-field>
      <mat-label>Lead Sentence</mat-label>
      <mat-select [formControl]="form.get('leadSentence.option')">
        <mat-option value="he">He</mat-option>
        <mat-option value="she">She</mat-option>
        <mat-option value="they">They</mat-option>
      </mat-select>
    </mat-form-field>
    <angular-editor
      [config]="editorConfig"
      [formControl]="form.get('leadSentence.text').get(form.get('leadSentence.option').value)"
    ></angular-editor>
  </div>
  <div class="scales">
    <div class="type-of-scale">
      <h2>Type of Scale</h2>
      <mat-button-toggle-group formControlName="useSubscale">
        <mat-button-toggle [value]="false">Superscale</mat-button-toggle>
        <mat-button-toggle [value]="true">Subscale</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div>
      <h2 *ngIf="form.get('useSubscale').value">Subscales</h2>
      <div *ngIf="form.get('useSubscale').value" class="subscales" formGroupName="subscales">
        <div class="subscale" formGroupName="left">
          <mat-form-field>
            <mat-label>Subscale Left</mat-label>
            <input
              matInput
              required
              type="text"
              (ngModelChange)="this.subscaleFieldUpdate.next($event)"
              formControlName="id"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displaySubscale">
              <mat-option *ngFor="let subscale of subscales | filter: subscaleField" [value]="subscale">
                <div [matTooltip]="subscale.title">
                  <p class="report-scale-title-text" [matTooltip]="subscale.title">{{ subscale.title }}</p>
                  <p *ngIf="subscale.description" class="small-text">{{ subscale.description }}</p>
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="subscale" formGroupName="center">
          <mat-form-field>
            <mat-label>Subscale Center</mat-label>
            <input
              matInput
              required
              type="text"
              (ngModelChange)="this.subscaleFieldUpdate.next($event)"
              formControlName="id"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displaySubscale">
              <mat-option
                *ngFor="let subscale of subscales | filter: subscaleField"
                [value]="subscale"
                (onSelectionChange)="setSubscaleCenterTitle()"
              >
                <div [matTooltip]="subscale.title">
                  <p class="report-scale-title-text">{{ subscale.title }}</p>
                  <p *ngIf="subscale.description" class="small-text">{{ subscale.description }}</p>
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="subscale" formGroupName="right">
          <mat-form-field>
            <mat-label>Subscale Right</mat-label>
            <input
              matInput
              required
              type="text"
              (ngModelChange)="this.subscaleFieldUpdate.next($event)"
              formControlName="id"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displaySubscale">
              <mat-option *ngFor="let subscale of subscales | filter: subscaleField" [value]="subscale">
                <div [matTooltip]="subscale.title">
                  <p class="report-scale-title-text">{{ subscale.title }}</p>
                  <p *ngIf="subscale.description" class="small-text">{{ subscale.description }}</p>
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
      <h2 *ngIf="!form.get('useSubscale').value">Superscales</h2>
      <div *ngIf="!form.get('useSubscale').value" class="superscales" formGroupName="superscales">
        <div class="superscale">
          <mat-form-field>
            <mat-label>Superscale Left</mat-label>
            <input
              matInput
              required
              type="text"
              (ngModelChange)="this.superscaleFieldUpdate.next($event)"
              formControlName="left"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displaySuperscale">
              <mat-option *ngFor="let superscale of superscales | filter: superscaleField" [value]="superscale">
                <div [matTooltip]="superscale.title">
                  <p class="report-scale-title-text">{{ superscale.title }}</p>
                  <p *ngIf="superscale.description" class="small-text">{{ superscale.description }}</p>
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="superscale" formGroupName="center">
          <mat-form-field>
            <mat-label>Subscale Center</mat-label>
            <input
              matInput
              required
              type="text"
              (ngModelChange)="this.subscaleFieldUpdate.next($event)"
              formControlName="id"
              [matAutocomplete]="subscaleAuto"
            />
            <mat-autocomplete #subscaleAuto="matAutocomplete" [displayWith]="displaySubscale">
              <mat-option
                *ngFor="let subscale of subscales | filter: subscaleField"
                [value]="subscale"
                (onSelectionChange)="setSubscaleCenterTitle()"
              >
                <div [matTooltip]="subscale.title">
                  <p class="report-scale-title-text">{{ subscale.title }}</p>
                  <p *ngIf="subscale.description" class="small-text">{{ subscale.description }}</p>
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="superscale">
          <mat-form-field>
            <mat-label>Superscale Right</mat-label>
            <input
              matInput
              required
              type="text"
              (ngModelChange)="this.superscaleFieldUpdate.next($event)"
              formControlName="right"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displaySuperscale">
              <mat-option *ngFor="let superscale of superscales | filter: superscaleField" [value]="superscale">
                <div [matTooltip]="superscale.title">
                  <p class="report-scale-title-text">{{ superscale.title }}</p>
                  <p *ngIf="superscale.description" class="small-text">{{ superscale.description }}</p>
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <mat-form-field>
    <mat-label>Chart title</mat-label>
    <input matInput type="text" formControlName="chartTitle" />
  </mat-form-field>
  <div class="agility-chart">
    <app-violin
      chartName="Agility"
      [leftValue]="50"
      [centerValue]="40"
      [rightValue]="50"
      leftLabel="Left Label"
      centerLabel="Center Label"
      rightLabel="Right Label"
      [scalesLeftResults]="violinChartScalesResults.left"
      [scalesRightResults]="violinChartScalesResults.center"
      [scalesCenterResults]="violinChartScalesResults.right"
    ></app-violin>
    <div class="labels" formGroupName="labels">
      <h2>Labels</h2>
      <mat-form-field>
        <mat-label>Label Left</mat-label>
        <input matInput type="text" required formControlName="left" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Label Center</mat-label>
        <input matInput type="text" required formControlName="center" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Label Right</mat-label>
        <input matInput type="text" required formControlName="right" />
      </mat-form-field>
    </div>
  </div>

  <div style="display: flex; justify-content: space-between; align-items: center">
    <h2>Default Sentence Toggle</h2>
    <mat-button-toggle-group class="default-sentence-toggle" [formControl]="form.get('defaultSentenceEnabled')">
      <mat-button-toggle [value]="true">Enable</mat-button-toggle>
      <mat-button-toggle [value]="false">Disable</mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <h2>Agility Ranges {{ subscaleCenterTitle ? subscaleCenterTitle : '' }}</h2>
  <div>
    <h3>Agility - Both above 50 Default Sentence</h3>
    <angular-editor
      [config]="editorConfig"
      [formControl]="form.get('agilityBothAbove50DefaultSentence')"
    ></angular-editor>
  </div>
  <div>
    <h3>Agility - Both below 50 Default Sentence</h3>
    <angular-editor
      [config]="editorConfig"
      [formControl]="form.get('agilityBothBelow50DefaultSentence')"
    ></angular-editor>
  </div>
  <div>
    <h3>Agility - One above 50 One below 50 Default Sentence</h3>
    <angular-editor
      [config]="editorConfig"
      [formControl]="form.get('agilityOneAbove50OneBelow50DefaultSentence')"
    ></angular-editor>
  </div>
  <div class="agility-ranges" formGroupName="ranges">
    <div class="agility-range-header">
      <h3>Agility - Both above 50</h3>
      <button
        mat-flat-button
        color="primary"
        (click)="addRange('above50')"
        type="button"
        [disabled]="form.get('ranges.above50').invalid"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <div class="above-50-ranges" formGroupName="above50">
      <div class="form">
        <mat-form-field>
          <mat-label>From</mat-label>
          <input matInput type="number" required min="0" formControlName="from" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>To</mat-label>
          <input matInput type="number" required min="0" formControlName="to" />
        </mat-form-field>
        <mat-form-field formGroupName="sentence">
          <mat-label>Sentence</mat-label>
          <mat-select required formControlName="option">
            <mat-option value="he">He</mat-option>
            <mat-option value="she">She</mat-option>
            <mat-option value="they">They</mat-option>
          </mat-select>
        </mat-form-field>
        <angular-editor
          [config]="editorConfig"
          [formControl]="form.get('ranges.above50.sentence.text').get(form.get('ranges.above50.sentence.option').value)"
        ></angular-editor>
      </div>
      <div class="added-ranges">
        <h3><b>Added Ranges</b></h3>
        <div class="added-range header">
          <span>From</span>
          <span>To</span>
          <span>Texts</span>
          <span>Actions</span>
        </div>
        <div class="added-range" *ngFor="let range of addedRanges['above50']; let i = index">
          <span>{{ range.from }}</span>
          <span>{{ range.to }}</span>
          <div class="texts">
            <div class="he" [innerHtml]="range.sentence?.he || range.reportText?.he"></div>
            <div class="she" [innerHtml]="range.sentence?.she || range.reportText?.she"></div>
            <div class="they" [innerHtml]="range.sentence?.they || range.reportText?.they"></div>
          </div>
          <div>
            <button class="edit-button" mat-flat-button color="primary" (click)="onEditRange('above50', i)">
              <mat-icon>edit</mat-icon>
              Edit
            </button>
            <button mat-flat-button color="accent" (click)="removeRange('above50', i)" type="button">
              <mat-icon>delete</mat-icon>
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="agility-range-header">
      <h3>Agility - Both below 50</h3>
      <button
        mat-flat-button
        color="primary"
        (click)="addRange('below50')"
        type="button"
        [disabled]="form.get('ranges.below50').invalid"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <div class="below-50-ranges" formGroupName="below50">
      <div class="form">
        <mat-form-field>
          <mat-label>From</mat-label>
          <input matInput type="number" required min="0" formControlName="from" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>To</mat-label>
          <input matInput type="number" required min="0" formControlName="to" />
        </mat-form-field>
        <mat-form-field formGroupName="sentence">
          <mat-label>Sentence</mat-label>
          <mat-select formControlName="option" required>
            <mat-option value="he">He</mat-option>
            <mat-option value="she">She</mat-option>
            <mat-option value="they">They</mat-option>
          </mat-select>
        </mat-form-field>
        <angular-editor
          [config]="editorConfig"
          [formControl]="form.get('ranges.below50.sentence.text').get(form.get('ranges.below50.sentence.option').value)"
        >
        </angular-editor>
      </div>
      <div class="added-ranges">
        <h3><b>Added Ranges</b></h3>
        <div class="added-range header">
          <span>From</span>
          <span>To</span>
          <span>Texts</span>
          <span>Actions</span>
        </div>
        <div class="added-range" *ngFor="let range of addedRanges['below50']; let i = index">
          <span>{{ range.from }}</span>
          <span>{{ range.to }}</span>
          <div class="texts">
            <div class="he" [innerHtml]="range.sentence?.he || range.reportText?.he"></div>
            <div class="she" [innerHtml]="range.sentence?.she || range.reportText?.she"></div>
            <div class="they" [innerHtml]="range.sentence?.they || range.reportText?.they"></div>
          </div>
          <div>
            <button class="edit-button" mat-flat-button color="primary" (click)="onEditRange('below50', i)">
              <mat-icon>edit</mat-icon>
              Edit
            </button>
            <button mat-flat-button color="accent" (click)="removeRange('below50', i)" type="button">
              <mat-icon>delete</mat-icon>
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="agility-range-header">
      <h3>Agility - One above 50 One below 50</h3>
      <button
        mat-flat-button
        color="primary"
        (click)="addRange('aboveBelow50')"
        type="button"
        [disabled]="form.get('ranges.aboveBelow50').invalid"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <div class="above-below-ranges" formGroupName="aboveBelow50">
      <div class="form">
        <mat-form-field>
          <mat-label>From</mat-label>
          <input matInput type="number" required min="0" formControlName="from" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>To</mat-label>
          <input matInput type="number" required min="0" formControlName="to" />
        </mat-form-field>
        <mat-form-field formGroupName="sentence">
          <mat-label>Sentence</mat-label>
          <mat-select formControlName="option" required>
            <mat-option value="he">He</mat-option>
            <mat-option value="she">She</mat-option>
            <mat-option value="they">They</mat-option>
          </mat-select>
        </mat-form-field>
        <angular-editor
          [config]="editorConfig"
          [formControl]="
            form.get('ranges.aboveBelow50.sentence.text').get(form.get('ranges.aboveBelow50.sentence.option').value)
          "
        ></angular-editor>
      </div>
      <div class="added-ranges">
        <h3><b>Added Ranges</b></h3>
        <div class="added-range header">
          <span>From</span>
          <span>To</span>
          <span>Texts</span>
          <span>Actions</span>
        </div>
        <div class="added-range" *ngFor="let range of addedRanges['aboveBelow50']; let i = index">
          <span>{{ range.from }}</span>
          <span>{{ range.to }}</span>
          <div class="texts">
            <div class="he" [innerHtml]="range.sentence?.he || range.reportText?.he"></div>
            <div class="she" [innerHtml]="range.sentence?.she || range.reportText?.she"></div>
            <div class="they" [innerHtml]="range.sentence?.they || range.reportText?.they"></div>
          </div>
          <div>
            <button class="edit-button" mat-flat-button color="primary" (click)="onEditRange('aboveBelow50', i)">
              <mat-icon>edit</mat-icon>
              Edit
            </button>
            <button mat-flat-button color="accent" (click)="removeRange('aboveBelow50', i)" type="button">
              <mat-icon>delete</mat-icon>
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="double-ranges-header">
    <h2>Double Ranges</h2>
    <button
      mat-flat-button
      color="primary"
      (click)="addDoubleRange()"
      type="button"
      [disabled]="form.get('doubleRanges').invalid"
    >
      <mat-icon>add</mat-icon>
    </button>
  </div>
  <div>
    <h3>Double Ranges Default Sentence</h3>
    <angular-editor [config]="editorConfig" [formControl]="form.get('doubleRangesDefaultSentence')"></angular-editor>
  </div>
  <div class="double-ranges" formGroupName="doubleRanges">
    <div class="form">
      <div class="ranges">
        <div class="left-ranges">
          <mat-form-field>
            <mat-label>From</mat-label>
            <input matInput type="number" required min="0" formControlName="from1" />
          </mat-form-field>
          <mat-form-field>
            <mat-label>To</mat-label>
            <input matInput type="number" required min="0" formControlName="to1" />
          </mat-form-field>
        </div>
        <div class="right-ranges">
          <mat-form-field>
            <mat-label>From</mat-label>
            <input matInput type="number" required min="0" formControlName="from2" />
          </mat-form-field>
          <mat-form-field>
            <mat-label>To</mat-label>
            <input matInput type="number" required min="0" formControlName="to2" />
          </mat-form-field>
        </div>
      </div>
      <div class="double-range-sentence">
        <mat-form-field formGroupName="sentence">
          <mat-label>Sentence</mat-label>
          <mat-select formControlName="option">
            <mat-option value="he">He</mat-option>
            <mat-option value="she">She</mat-option>
            <mat-option value="they">They</mat-option>
          </mat-select>
        </mat-form-field>
        <angular-editor
          [config]="editorConfig"
          [formControl]="form.get('doubleRanges.sentence.text').get(form.get('doubleRanges.sentence.option').value)"
        ></angular-editor>
      </div>
    </div>
    <div class="added-double-ranges">
      <h3><b>Added Double Ranges</b></h3>
      <div class="added-double-range top-header">
        <span>Left</span>
        <span>Right</span>
      </div>
      <div class="added-double-range header">
        <span>From</span>
        <span>To</span>
        <span>From</span>
        <span>To</span>
        <span>Texts</span>
        <span>Actions</span>
      </div>
      <div class="added-double-range item" *ngFor="let doubleRange of addedDoubleRanges; let i = index">
        <span>{{ doubleRange.from1 }}</span>
        <span>{{ doubleRange.to1 }}</span>
        <span>{{ doubleRange.from2 }}</span>
        <span>{{ doubleRange.to2 }}</span>
        <div class="texts">
          <div class="he" [innerHtml]="doubleRange.sentence?.he || doubleRange.reportText?.he"></div>
          <div class="she" [innerHtml]="doubleRange.sentence?.she || doubleRange.reportText?.she"></div>
          <div class="they" [innerHtml]="doubleRange.sentence?.they || doubleRange.reportText?.they"></div>
        </div>
        <div>
          <button class="edit-button" mat-flat-button color="primary" (click)="onEditDoubleRange(i)">
            <mat-icon>edit</mat-icon>
            Edit
          </button>
          <button mat-flat-button color="accent" (click)="removeDoubleRange(i)" type="button">
            <mat-icon>delete</mat-icon>
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
