<app-ordinal-paragraph-section
  *ngIf="section.type === 'OrdinalParagraph'"
  [ordinalParagraph]="section.subsection"
  [chart]="section?.chart"
></app-ordinal-paragraph-section>

<app-paragraph-section
  *ngIf="section.type === 'Paragraph'"
  [paragraph]="section.subsection"
  [chart]="section?.chart"
></app-paragraph-section>

<app-bullet-point-section
  *ngIf="section.type === 'BulletPoint'"
  [bulletPoint]="section.subsection"
  [chart]="section?.chart"
></app-bullet-point-section>

<app-all-combination-paragraph
  *ngIf="section.type === 'AllCombinationsParagraph'"
  [combinationParagraph]="section.subsection"
  [chart]="section?.chart"
></app-all-combination-paragraph>

<app-chart-subsection
  *ngIf="section.type === 'Chart'"
  [chart]="section.subsection"
  [chart2]="section?.chart"
></app-chart-subsection>

<app-scorecards-section
  *ngIf="section.type === 'Scorecards'"
  [subsection]="section.subsection"
  [chart]="section?.chart"
></app-scorecards-section>
