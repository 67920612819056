<h1>{{ bulletPoint?.heading }}</h1>

<div *ngIf="chart?.id">
  <span
    >Chart: <b>{{ chart.name }}</b></span
  >
  <div class="chart-preview" [ngSwitch]="chart.base">
    <app-hexagon class="chart" *ngSwitchCase="'HEXAGON'" [chartId]="chart.id" [testGroupId]="1"> </app-hexagon>

    <app-circular-plot class="chart" *ngSwitchCase="'FOUR_PLUS_ONE_CARTESIAN'" [chartId]="chart.id" [testGroupId]="1">
    </app-circular-plot>

    <app-square-plot class="chart" *ngSwitchCase="'CIRCLE_IN_CARTESIAN'" [chartId]="chart.id" [testGroupId]="1">
    </app-square-plot>

    <app-blossom-graph-preview class="chart" *ngSwitchCase="'BLOSSOM_GRAPH'" [chartId]="chart.id" [testGroupId]="1">
    </app-blossom-graph-preview>
  </div>
</div>

<p [innerHtml]="bulletPoint?.leadSentence?.he"></p>

<div *ngFor="let sentence of bulletPoint?.sentences; let i = index" class="sentences">
  <div class="rearrange">
    <button [disabled]="i === 0" mat-flat-button (click)="moveUpSentence(bulletPoint.id, sentence.id, i)">
      <mat-icon>keyboard_arrow_up</mat-icon>
    </button>

    <button
      [disabled]="i === bulletPoint.sentences.length - 1"
      mat-flat-button
      (click)="moveDownSentence(bulletPoint.id, sentence.id, i)"
    >
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
  </div>

  <table *ngIf="sentence.useSubscale">
    <colgroup>
      <col />
      <col class="num-col" />
    </colgroup>
    <thead>
      <tr>
        <th>Subscales</th>
        <th>Weight</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let subscale of sentence.subscales">
        <td>{{ subscale.title }}</td>
        <td>{{ subscale.weight }}</td>
      </tr>
    </tbody>
  </table>

  <table *ngIf="!sentence.useSubscale">
    <thead>
      <tr>
        <th>Superscales</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let superscale of sentence.superscales">
        <td>{{ superscale.title }}</td>
      </tr>
    </tbody>
  </table>

  <table>
    <colgroup>
      <col class="num-col" />
      <col class="num-col" />
      <col />
    </colgroup>
    <thead>
      <tr>
        <th>From</th>
        <th>To</th>
        <th>Text</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let range of sentence.ranges">
        <td>{{ range.from }}</td>
        <td>{{ range.to }}</td>
        <td>
          <div [innerHtml]="range.text?.he"></div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
