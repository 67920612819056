import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-scorecards-section',
  templateUrl: './scorecards-section.component.html',
  styleUrls: ['./scorecards-section.component.scss'],
})
export class ScorecardsSectionComponent implements OnInit {
  @Input() subsection: any;
  @Input() chart: any;
  scoreCardsSection: any;

  currentRangeText: string;

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    this.scoreCardsSection = {
      ...this.subsection,
      scoreCards: this.subsection.scoreCards.map((scorecard) => ({
        ...scorecard,
        ranges: {
          outOfRangeLow: scorecard.ranges.find((range) => range.type === 'OUT_OF_RANGE_LOW'),
          cautionaryLow: scorecard.ranges.find((range) => range.type === 'CAUTIONARY_LOW'),
          optimal: scorecard.ranges.find((range) => range.type === 'OPTIMAL'),
          outOfRangeHigh: scorecard.ranges.find((range) => range.type === 'OUT_OF_RANGE_HIGH'),
          cautionaryHigh: scorecard.ranges.find((range) => range.type === 'CAUTIONARY_HIGH'),
        },
      })),
    };
  }

  openRangeTextPreview(ref: TemplateRef<any>, text: string) {
    this.currentRangeText = text;
    this.dialog.open(ref);
  }
}
