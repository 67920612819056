import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ViewportScroller } from '@angular/common';

// service
import { EmailService } from '@app/core/services/email.service';
import { SendEmailService } from '@app/core/services/send-email.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';

// models
import { MatDialog } from '@angular/material/dialog';
import { EmailSet } from '@app/core/models/email-set.model';
import { PsyEmail } from '@app/core/models/psy-email.model';
import { UserInvite } from '@app/core/models/user-invite';
import { EMAIL_TYPES } from '@app/shared/constants/emailTypes';
import { environment } from 'src/environments/environment';
import { PsyTestGroup } from '@app/core/models/psy-test-group.model';
import { TestGroupService } from '@app/core/services/test-group.service';

@Component({
  selector: 'app-psb-send-email',
  templateUrl: './psb-send-email.component.html',
  styleUrls: ['./psb-send-email.component.scss'],
})
export class PsbSendEmailComponent implements OnInit, OnChanges {
  @Input() roleMandateId?: number;
  @Input() testGroupId?: number;
  @Input() connectionIndicatorId?: number;
  @Input() users: UserInvite[];
  @Input() filteredEmailTypes: any[];
  @Input() companyId: number;
  @Input() psyTestGroup?: PsyTestGroup;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '130px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Invite message.',
    defaultParagraphSeparator: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [['insertImage', 'insertVideo']],
  };

  emailSet: EmailSet;
  sources: String[] = [];
  emailForm: FormGroup;
  emailTypes: any = [];
  originalEmailSet: EmailSet;

  constructor(
    private sendEmailService: SendEmailService,
    private snackBar: SnackBarService,
    private viewportScroller: ViewportScroller,
    public dialog: MatDialog,
    private emailService: EmailService,
    private testGroupService: TestGroupService,
  ) {}

  ngOnInit(): void {
    this.emailForm = new FormGroup({
      message: new FormControl('', [Validators.required]),
      subject: new FormControl('', [Validators.required]),
      filter: new FormControl(''),
      from: new FormControl({ value: '', disabled: true }, [Validators.required]),
      emailTypeId: new FormControl(null, [Validators.required]),
      template: new FormControl(''),
    });

    if (this.roleMandateId) {
      this.emailService.findAllByRoleMandateId(this.roleMandateId).subscribe((emailSet) => {
        this.emailSet = emailSet;
        this.sources = emailSet.sources;
        if (this.sources) {
          this.setEmailSource();
        }

        if (emailSet.email.length) {
          this.setEmail(emailSet.email[0]);
        }
      });
    } else if (this.connectionIndicatorId) {
      this.emailService.findAllByConnectionIndicatorId(this.connectionIndicatorId).subscribe((emailSet) => {
        this.emailSet = emailSet;
        this.sources = emailSet.sources;
        if (this.sources) {
          this.setEmailSource();
        }

        if (emailSet.email.length) {
          this.setEmail(emailSet.email[0]);
        }
      });
    } else if (this.testGroupId) {
      this.emailService.findAllByTestGroupId(this.testGroupId).subscribe((emailSet) => {
        this.emailSet = emailSet;
        if (this.psyTestGroup.logoEnabled) {
          this.emailSet.email?.forEach((email) => {
            email.email = this.emailService.replaceImgSrc(email.email, this.psyTestGroup.companyId);
          });
        }
        this.sources.push(
          this.psyTestGroup.fromEmailAddress ? this.psyTestGroup.fromEmailAddress : 'psybil@psynetgroup.com',
        );
        this.emailForm.patchValue({
          from: this.sources[0],
        });
        if (this.sources) {
          this.setEmailSource();
        }
        if (emailSet?.email[0]) {
          this.setEmail(emailSet.email[0]);
        }
      });
    }
  }

  ngOnChanges(): void {
    if (!this.originalEmailSet && this.emailSet) {
      this.originalEmailSet = Object.assign({}, this.emailSet);
    }
    if (this.filteredEmailTypes) {
      if (this.filteredEmailTypes.length === 0) {
        this.emailTypes = EMAIL_TYPES;
      } else {
        this.emailTypes = this.filteredEmailTypes.sort((a, b) => a.id - b.id);
      }
    }
    if (this.emailSet) {
      this.emailSet.email = this.originalEmailSet.email.filter((email) => {
        return this.emailTypes.map((item) => item.id).includes(email.emailTypeId);
      });
      this.emailForm.controls['subject'].patchValue(this.emailSet.email[0].subject);
      this.emailForm.controls['template'].patchValue(this.emailSet.email[0].id);
      this.emailForm.controls['emailTypeId'].patchValue(this.emailSet.email[0].emailTypeId);
      this.emailForm.controls['message'].patchValue(this.emailSet.email[0].email);
    }
  }

  setEmailSource() {
    if (this.sources.length > 1) {
      this.emailForm.controls['from'].enable();
    }
    this.emailForm.patchValue({ from: typeof this.sources[0] !== 'undefined' ? this.sources[0] : '' });
  }

  selectEmail(emailTypeId: any, event: any) {
    const selectedEmail = this.emailSet.email.filter((emailSet) => emailSet.emailTypeId === emailTypeId)[0];
    if (event.isUserInput) {
      let replacedEmail;
      if (selectedEmail.email.indexOf('assets/images/psynet_logo.jpg') > -1) {
        replacedEmail = selectedEmail.email.replace(
          'src="assets/images/psynet_logo.jpg"',
          'src="https://psybil.io/assets/images/psynet_logo.jpg"',
        );
      }

      this.emailForm.patchValue({
        message: replacedEmail ? replacedEmail : selectedEmail.email,
        subject: selectedEmail.subject,
        template: selectedEmail.id,
        emailTypeId: selectedEmail.emailTypeId,
      });
    }
  }

  setEmail(email: PsyEmail) {
    let replacedEmail;
    if (email.email.indexOf('assets/images/psynet_logo.jpg') > -1) {
      replacedEmail = email.email.replace(
        'src="assets/images/psynet_logo.jpg"',
        'src="https://psybil.io/assets/images/psynet_logo.jpg"',
      );
    }

    this.emailForm.patchValue({
      message: replacedEmail ? replacedEmail : email.email,
      subject: email.subject,
      template: email.id,
      emailTypeId: email.emailTypeId,
    });
  }

  onUpsertMessage() {
    let emailTemplate;
    let replacedMessage;

    if (this.emailForm.get('message').value.indexOf('assets/images/psynet_logo.jpg') > -1) {
      replacedMessage = this.emailForm
        .get('message')
        .value.replace(
          '<img src="https://psybil.io/assets/images/psynet_logo.jpg">',
          '<img src="https://psybil.io/assets/images/psynet_logo.jpg" style="width: 80px; height: 80px;">',
        );
    }

    if (this.emailForm.get('template').value) {
      emailTemplate = this.emailSet.email.find((e) => e.id == this.emailForm.get('template').value);
      emailTemplate.email = replacedMessage ? replacedMessage : this.emailForm.get('message').value;
      emailTemplate.subject = this.emailForm.get('subject').value;
      emailTemplate.emailTypeId = this.emailForm.get('emailTypeId').value;
    } else {
      emailTemplate = {
        message: replacedMessage ? replacedMessage : this.emailForm.get('message').value,
        subject: this.emailForm.get('subject').value,
        emailTypeId: this.emailForm.get('emailTypeId').value,
      };
    }

    if (this.connectionIndicatorId) {
      emailTemplate['connectionIndicatorId'] = this.connectionIndicatorId;
    }

    if (this.roleMandateId) {
      emailTemplate['roleMandateId'] = this.roleMandateId;
    }

    if (this.testGroupId) {
      emailTemplate['testGroupId'] = this.testGroupId;
    }

    if (this.emailForm.get('template').value) {
      this.emailService.update(emailTemplate).subscribe(
        () => this.openSnackBar('Invite e-mail updated successfully!'),
        () => this.openSnackBar('Some error occurred while trying to update the invite e-mail'),
      );
    } else {
      this.emailService.save(emailTemplate).subscribe(
        () => this.openSnackBar('Invite e-mail updated successfully!'),
        () => this.openSnackBar('Some error occurred while trying to update the invite e-mail'),
      );
    }
  }

  sendEmail() {
    let reminder = {
      subject: this.emailForm.get('subject').value,
      message: this.emailForm.get('message').value,
      users: this.users,
      from: this.emailForm.get('from').value,
      emailTypeId: this.emailForm.get('emailTypeId').value,
      companyLogo: this.companyId ? `<img src="${environment.url}/company/11/logo" />` : '',
    };

    if (this.connectionIndicatorId) {
      reminder['connectionIndicatorId'] = this.connectionIndicatorId;
    }
    if (this.roleMandateId) {
      reminder['roleMandateId'] = this.roleMandateId;
    }
    if (this.testGroupId) {
      reminder['testGroupId'] = this.testGroupId;
    }

    this.sendEmailService.sendReminders(reminder).subscribe(
      () => {
        this.viewportScroller.scrollToPosition([0, 0]);
        this.emailForm.get('filter').reset();
        this.sendEmailService.setInviteSentListener(true);
        this.openSnackBar('Invites sent successfully!');
      },
      (error) => {
        this.openSnackBar('Some error occurred while trying to send the invites. Contact the support.');
      },
    );
  }

  openSnackBar(message: string) {
    this.snackBar.info(message);
  }
}
