import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, AbstractControl, Validators, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

// constants
import { EVENT_SUCCESS } from '@app/core/constants';
import { PsyChart } from '@app/core/models/psy-chart.model';
import { ChartRangeController } from 'src/generated/v3';
import { SnackBarService } from '@app/core/services/snack-bar.service';

@Component({
  selector: 'app-blossom-chart-pistil-ranges',
  templateUrl: './blossom-chart-pistil-ranges.component.html',
  styleUrls: ['./blossom-chart-pistil-ranges.component.scss'],
})
export class BlossomChartPistilRangesComponent implements OnInit {
  @Input() chart: PsyChart;

  pistilForm: FormGroup;
  textForRangeTitle: string;
  addTextDialogRef: MatDialogRef<any>;
  addTextEditorControl = new FormControl('', [Validators.required]);

  constructor(
    private chartRangeController: ChartRangeController,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private snackBar: SnackBarService,
  ) {
    this.pistilForm = this.fb.group({
      pistils: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    this.getFormData(this.chart.id);
  }

  getFormData(chartId: number) {
    this.chartRangeController.chartRangeControllerGetChartPistilRanges(chartId).subscribe((pistils) => {
      this.loadFormData(pistils);
    });
  }

  private loadFormData(pistils: any[]): void {
    pistils.map((pistil) => {
      this.pistilsFormArray.push(this.createPistilGroup(pistil));
    });
  }

  get pistilsFormArray() {
    return this.pistilForm.get('pistils') as FormArray;
  }

  resetPistilsFormArray() {
    (<FormArray>this.pistilForm.get('pistils')).clear();
  }

  createPistilGroup(pistil: any): FormGroup {
    return this.fb.group({
      id: [pistil.id],
      petalId: [pistil.petal.id],
      label: [pistil.label],
      petalLabel: [pistil.petal?.label || ''],
      ranges: this.fb.array(
        Array.from({ length: 5 }).map((_, index) => {
          const range = pistil.ranges.find((r) => r.order === index);
          return this.fb.group({
            order: [index],
            min: [range ? range.min : 0],
            max: [range ? range.max : 0],
            description: [range ? range.description : ''],
          });
        }),
      ),
    });
  }

  openAddTextDialog(ref: TemplateRef<any>, textControl: AbstractControl, title: string) {
    this.addTextEditorControl.setValue(textControl.value);
    this.textForRangeTitle = title;
    this.addTextDialogRef = this.dialog.open(ref);
    this.addTextDialogRef.afterClosed().subscribe((result) => {
      if (result?.event !== EVENT_SUCCESS) {
        return;
      }
      textControl.setValue(this.addTextEditorControl.value);
      this.addTextEditorControl.setValue('');
    });
  }

  removeTextDialog(textControl: AbstractControl, title: string) {
    this.addTextEditorControl.setValue('');
    this.textForRangeTitle = title;

    textControl.setValue(this.addTextEditorControl.value);
  }

  closeAddTextDialog() {
    this.addTextDialogRef.close();
  }

  saveAddTextDialog() {
    this.addTextDialogRef.close({ event: EVENT_SUCCESS });
  }

  onSave() {
    const ranges = this.clearEmptyRanges();
    this.chartRangeController
      .chartRangeControllerUpdateChartPistilRanges({
        chartId: this.chart.id,
        ranges,
      })
      .subscribe((res) => {
        this.resetPistilsFormArray();
        this.getFormData(this.chart.id);
        this.snackBar.info('Preferred ranges are saved successfully');
      });
  }

  clearEmptyRanges() {
    const { value } = this.pistilForm;

    let ranges = [];
    value.pistils.forEach((pistil) => {
      pistil.ranges.forEach((range) => {
        if (range.description !== '') {
          ranges.push({
            pistilId: pistil.id,
            ...range,
          });
        }
      });
    });

    return ranges;
  }

  getValuesRange(pistil: any, order: number) {
    const range = pistil.ranges.find((range) => range.order === order);
    if (range) {
      return range;
    }

    return null;
  }
}
