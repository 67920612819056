import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { forkJoin } from 'rxjs';

// components
import { PsyOptionsDialogComponent } from '@app/shared/components/psy-options-dialog/psy-options-dialog.component';
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';

// models
import { PsbItem } from '@app/core/models/psb-item.model';
import { ReportModel } from '@app/core/models/report-model.model';
import { PsbOptionItem } from '@app/core/models/psb-option-item.model';

// services
import { TestGroupService as DeprecatedTestGroupService } from '@app/core/services/test-group.service';
import { ReportService } from '@app/core/services/report.service';
import { ReportModelService } from '@app/core/services/report-model.service';
import { SuperscaleService } from '@app/core/services/superscale.service';
import { SnackBarService } from '@app/core/services/snack-bar.service';

// constants
import { EVENT_CANCEL, EVENT_ADD, EVENT_SAVE, ROLE_TGM, ROLE_ORGANIZATIONAL_ADMIN } from '@app/core/constants';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { SubscaleService, TestGroupService } from '../../../../../generated/v2';

@Component({
  selector: 'app-test-group-report',
  templateUrl: './test-group-report.component.html',
  styleUrls: ['./test-group-report.component.scss'],
})
export class TestGroupReportComponent implements OnInit {
  @Input() testGroupId: number;
  @Input() role: string;
  @Input() testGroupTitle: string;
  @Output() updateReportInfo = new EventEmitter<void>();

  reportModel: ReportModel;
  reportModels: ReportModel[] = [];
  reportModelExpanded = false;
  scoreCardExpanded = false;
  reportPsbItems: PsbItem[] = [];
  testPsbItems: PsbItem[] = [];
  superscalePsbItems: PsbItem[] = [];
  reportExpanded = false;
  reportModalPsbOptionItems: PsbOptionItem[] = [];
  reportPsbOptionItems: PsbOptionItem[] = [];
  superscalePsbOptionItems: PsbOptionItem[] = [];
  scoreCardTriggerControl = new FormControl(false);
  updateScoreCardTrigger = false;
  isAdmin = true;
  availableTests: any;

  constructor(
    private deprecatedTestGroupService: DeprecatedTestGroupService,
    private reportService: ReportService,
    private reportModelService: ReportModelService,
    private superscaleService: SuperscaleService,
    private snackBar: SnackBarService,
    private subscaleService: SubscaleService,
    private testGroupService: TestGroupService,
    private router: Router,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    if ([ROLE_TGM, ROLE_ORGANIZATIONAL_ADMIN].includes(this.role)) {
      this.scoreCardExpanded = true;
      this.isAdmin = false;
    }

    if (this.isAdmin) {
      forkJoin([
        this.deprecatedTestGroupService.getAllReportById(this.testGroupId),
        this.deprecatedTestGroupService.getReportModelByTestGroupId(this.testGroupId),
        this.reportService.getAll(),
        this.reportModelService.getAll(),
        this.deprecatedTestGroupService.getPsyTestsById(this.testGroupId),
        this.deprecatedTestGroupService.getAllSuperscaleById(this.testGroupId),
        this.superscaleService.getAll(),
        this.deprecatedTestGroupService.getScoreCardTrigger(this.testGroupId),
      ]).subscribe((results) => {
        this.reportPsbItems = results[0].map((report) => {
          return {
            id: report.id,
            title: report.title,
          };
        });

        this.reportModel = results[1];

        this.reportPsbOptionItems = results[2].map((report) => {
          return {
            id: report.id,
            itemName: report.title,
          };
        });

        this.reportModels = results[3];

        this.reportModalPsbOptionItems = results[3].map((reportModel) => {
          return {
            id: reportModel.id,
            itemName: reportModel.title,
          };
        });

        this.availableTests = results[4].filter((psyTest) => psyTest.available === true);
        this.testPsbItems = this.availableTests.map((test) => {
          return {
            id: test.testId,
            testId: test.testId,
            testGroupId: test.testGroupId,
            title: test.title,
            psyTestGroupTestScales: test.psyTestGroupTestScales,
          };
        });

        this.superscalePsbItems = results[5].map((superscale) => {
          return {
            id: superscale.id,
            title: superscale.title,
          };
        });

        results[6].forEach((superscale) => {
          const item: PsbOptionItem = {
            id: superscale.id,
            itemName: superscale.title,
          };

          let isSuperscaleAvailable = this.superscalePsbItems.some((e) => e.id == item.id);
          if (!isSuperscaleAvailable) {
            this.superscalePsbOptionItems.push(item);
          }
        });

        if (results[7] && 'showScoreCard' in results[7]) {
          const showScoreCard = results[7].showScoreCard;
          if (results[7].id) {
            this.updateScoreCardTrigger = true;
          }
          this.scoreCardTriggerControl.patchValue(showScoreCard);
        }
      });
    } else {
      this.deprecatedTestGroupService.getScoreCardTrigger(this.testGroupId).subscribe((result) => {
        if (result && 'showScoreCard' in result) {
          const showScoreCard = result.showScoreCard;
          if (result.id) {
            this.updateScoreCardTrigger = true;
          }
          this.scoreCardTriggerControl.patchValue(showScoreCard);
        }
      });
    }
  }

  expandReportModel() {
    this.reportModelExpanded ? (this.reportModelExpanded = false) : (this.reportModelExpanded = true);
  }

  expandReports() {
    this.reportExpanded ? (this.reportExpanded = false) : (this.reportExpanded = true);
  }

  expandScoreCards() {
    this.scoreCardExpanded ? (this.scoreCardExpanded = false) : (this.scoreCardExpanded = true);
  }

  onAddReportModel() {
    const addReportModal = this.dialog.open(PsyOptionsDialogComponent, {
      data: {
        items: this.reportModalPsbOptionItems,
        title: 'Create or edit a Psy Test Group Report Model',
        isAddButton: true,
        addButtonName: 'Create a new Report Model',
        formFieldName: 'Report Model',
      },
    });

    addReportModal.afterClosed().subscribe((result) => {
      if (typeof result !== 'undefined' && result.event !== EVENT_CANCEL) {
        if (result.event === EVENT_ADD) {
          this.router.navigate(['/report-model']);
        } else if (result.event === EVENT_SAVE) {
          this.deprecatedTestGroupService
            .addReportModelToTestGroup(this.testGroupId, result.data.item.id)
            .subscribe((res) => {
              this.reportModel = this.reportModels.filter((reportModel) => reportModel.id === result.data.item.id)[0];
              this.showCompleted('Report Model is added to Test Group');
              this.reportExpanded = true;
              this.deprecatedTestGroupService.reportModelUpdate$.next(this.reportModel);
              this.updateReportInfo.emit();
            });
        }
      }
    });
  }

  onAddReport() {
    const addReport = this.dialog.open(PsyOptionsDialogComponent, {
      data: { items: this.reportPsbOptionItems, title: 'Create a Psy Test Group Report', formFieldName: 'Report' },
    });

    addReport.afterClosed().subscribe((result) => {
      if (typeof result !== 'undefined' && result.event !== EVENT_CANCEL) {
        this.deprecatedTestGroupService.addReportToTestGroup(this.testGroupId, result.data.item.id).subscribe((res) => {
          const newReport = this.reportPsbOptionItems.filter((report) => report.id === result.data.item.id)[0];

          const newReportPsbItem = {
            id: newReport.id,
            title: newReport.itemName,
          };

          this.reportPsbItems = [...this.reportPsbItems, newReportPsbItem];
          this.showCompleted('Report is added to Test Group');
          this.reportExpanded = true;
          this.updateReportInfo.emit();
        });
      }
    });
  }

  onDeleteReport(reportId: number) {
    this.dialog
      .open(DeleteDialogComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result.event === EVENT_CANCEL) {
          return;
        }

        this.deprecatedTestGroupService.removeReportFromTestGroup(this.testGroupId, reportId).subscribe(() => {
          this.reportPsbItems = this.reportPsbItems.filter((item) => item.id !== reportId);
          this.showCompleted('Report is removed from Test Group');
          this.reportExpanded = true;
          this.updateReportInfo.emit();
        });
      });
  }

  onDeleteReportModel() {
    this.dialog
      .open(DeleteDialogComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result.event === EVENT_CANCEL) {
          return;
        }

        this.deprecatedTestGroupService
          .removeReportModelFromTestGroup(this.testGroupId, this.reportModel.id)
          .subscribe(() => {
            this.reportModel = null;
            this.showCompleted('Report is removed from Test Group');
            this.reportModelExpanded = true;
            this.deprecatedTestGroupService.reportModelUpdate$.next(this.reportModel);
            this.updateReportInfo.emit();
          });
      });
  }

  onDeleteSuperscale(id: any) {
    this.dialog
      .open(DeleteDialogComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result.event === EVENT_CANCEL) {
          return;
        }

        this.deprecatedTestGroupService.removeSuperscaleFromTestGroup(this.testGroupId, id).subscribe(() => {
          this.superscalePsbItems = this.superscalePsbItems.filter((superscale) => superscale.id !== id);
          this.showCompleted('Superscale is removed from Test Group');
          this.scoreCardExpanded = true;
        });
      });
  }

  addSuperscale() {
    const addSuperscale = this.dialog.open(PsyOptionsDialogComponent, {
      data: {
        items: this.superscalePsbOptionItems,
        title: 'Add a Psy Test Group Superscale',
        formFieldName: 'Superscale',
      },
    });

    addSuperscale.afterClosed().subscribe((result) => {
      if (typeof result !== 'undefined' && result.event !== EVENT_CANCEL) {
        this.deprecatedTestGroupService
          .addSuperscaleToTestGroup(this.testGroupId, result.data.item.id)
          .subscribe((res) => {
            const newSuperscale = this.superscalePsbOptionItems.filter(
              (superscale) => superscale.id === result.data.item.id,
            )[0];

            const newSuperscalePsbItem = {
              id: newSuperscale.id,
              title: newSuperscale.itemName,
            };

            this.superscalePsbItems = [...this.superscalePsbItems, newSuperscalePsbItem];
            this.showCompleted('Superscale is added to Test Group');
            this.scoreCardExpanded = true;
          });
      }
    });
  }

  showCompleted(message: string) {
    this.snackBar.info(message);
  }

  onViewSubscaleRanges(id: any) {
    this.router.navigate([`/psy-test-group/${this.testGroupId}/test/${id}/preferred-range-subscale`], {
      state: {
        testGroupTitle: this.testGroupTitle,
        testTitle: this.testPsbItems.filter((test) => test.id === id)[0].title,
      },
    });
  }

  onViewSuperscaleRanges() {
    this.router.navigate([`/psy-test-group/${this.testGroupId}/preferred-range-superscale`], {
      state: { testGroupTitle: this.testGroupTitle },
    });
  }

  onViewPercentiles(element: any) {
    this.router.navigate([`/psy-test-group/${this.testGroupId}/test/${element.id}/scale-list`], {
      state: { test: element.title },
    });
  }

  onScoreCardValChange() {
    const trigger = this.scoreCardTriggerControl.value;
    if (this.updateScoreCardTrigger) {
      this.deprecatedTestGroupService.updateScoreCardTrigger(this.testGroupId, trigger).subscribe((res) => {
        if (res) {
          this.showCompleted(`A Psy Test Group Scorecard Trigger is updated with identifier ${res.id}`);
        }
      });
    } else {
      this.deprecatedTestGroupService.saveScoreCardTrigger(this.testGroupId, trigger).subscribe((res) => {
        if (res) {
          this.showCompleted(`A new Psy Test Group Scorecard Trigger is created with identifier ${res.id}`);
          this.updateScoreCardTrigger = true;
        }
      });
    }
  }

  onTestScaleVisibleChange(testScaleVisible: any) {
    this.deprecatedTestGroupService
      .updateTestScaleVisible(this.testGroupId, testScaleVisible.index, testScaleVisible.visible)
      .subscribe((res) => {
        if (res) {
          this.showCompleted(`Test scale visible has been updated with success.`);
        }
      });
  }

  onSortChild([item, $event]: [any, CdkDragDrop<any, any>]) {
    // Rearrange items
    moveItemInArray(item.psyTestGroupTestScales, $event.previousIndex, $event.currentIndex);

    // Save order to server
    this.subscaleService
      .postTestTestGroupSortSubscales(
        item.testId,
        item.testGroupId,
        item.psyTestGroupTestScales.map((n) => n.subscaleId),
      )
      .subscribe();
  }

  onSort($event: CdkDragDrop<any, any>) {
    moveItemInArray(this.testPsbItems, $event.previousIndex, $event.currentIndex);

    // Save order to server
    this.testGroupService
      .postTestGroupSortTests(
        this.testGroupId,
        // TODO: testPsbItems field has wrong type and need to be refactored
        this.testPsbItems.map((t) => (t as any).testId),
      )
      .subscribe();
  }
}
